/*===========================
 =  Table of Contents
 0. Keyframes
 1. General
 1.1. Typography
 1.2. Section spacings
 1.3. Forms
 1.4. Buttons
 1.5. Colors & Badges
 1.6. Modals, Popups & Dropdowns
 1.7. Tables
 1.8. Tabs
 1.9. Social media links
 2. Header
 2.1. Header style 2
 2.2. Header style 3
 3. Footer
 5. Subheader
 5. Components
 6.. Loaders
 6.1. Blog Posts
 6.1.1. Blog Posts List
 6.1.2. Blog Post Details
 6.2. sidebar
 6.3. Products
 6.3.1. Products (List view)
 6.3.2 Products (Minimal View)
 6.3.3 Products Quick view & details
 6.3.4. Cart & Wishlist
 6.3.5. Checkout
 6.3.6 Products (Recent View)
 6.4. Categories Megamenu
 6.5. Banners
 6.6. Icon Blocks
 6.7. Call To Actions
 6.8.Login & Signup
 6.9. Error 404
 6.10. Testimonials
 6.11. Companies
 6.12. Newsletter Popup
 7. Misc
 8. Responsive
===========================*/
/* Google Fonts */
/*@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;600;700&display=swap");*/
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400&display=swap');

/*-------------------------------------------------------------------*/
/* === Keyframes === */
@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes swell {
  0%, 100% {
    transform: translate3d(0,-25px,0);
  }
  50% {
    transform: translate3d(0,5px,0);
  }
}
@keyframes pulseInOut{
  0%{opacity:1;transform:scale(.3)}
  100%{opacity:0;transform:scale(1.3)}
}
@keyframes pulseInOutSM{
  0%{opacity:1;transform:scale(.3)}
  100%{opacity:0;transform:scale(1.7)}
}

@-webkit-keyframes rot {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes rot {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes preloaderScale {
  100% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
    opacity: 0;
  }
}
@keyframes preloaderScale {
  100% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
    opacity: 0;
  }
}

@keyframes BounceUpDown {
    0% {
        transform: translateY(0);
    }

    25% {
        transform: translateY(-5px);
    }

    50% {
        transform: translateY(0);
    }

    75% {
        transform: translateY(-2px);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes BounceDownUp {
    0% {
        transform: translateY(0);
    }

    25% {
        transform: translateY(5px);
    }

    50% {
        transform: translateY(0);
    }

    75% {
        transform: translateY(2px);
    }

    100% {
        transform: translateY(0);
    }
}

@-webkit-keyframes sk-rotate {
    100% {
        -webkit-transform: rotate(360deg)
    }
}

@keyframes sk-rotate {
    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg)
    }
}

@-webkit-keyframes sk-bounce {

    0%,
    100% {
        -webkit-transform: scale(0.0)
    }

    50% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes sk-bounce {

    0%,
    100% {
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
    }

    50% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
}

@keyframes bannerscale {
    50% {
        transform: scale(1.2);
    }
}

/*======================
1.0. General
========================*/
.border-top,
.border-bottom{
  border-color: #efefef !important;
}
.stretch-img{
  width: calc(100% + 410px);
  transform: translateX(-260px);
}

.z-index-3{
  z-index: 3;
}
.overflow-hidden{
  overflow: hidden;
}
.absolute-img{
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  object-fit: cover;
  height: 100%;
}

.img-group{
  position: relative;
  text-align: center;
  padding: 80px;
}
.img-group-inner{
  position: relative;
}
.img-group-inner span{
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  background-color: #f36525;
}
.img-group-inner span + span{
  top: auto;
  right: auto;
  left: -10px;
  bottom: -10px;
  background-color: #fff;
}
.img-group img:nth-child(2){
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.img-group img:nth-child(3){
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.img-group-2{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.img-group-2 img + img{
  margin-left: auto;
  margin-top: -50%;
}

.border{
  border: 2px solid #efefef !important;
}
.vertical-seperator{
  display: block;
  width: 3px;
  height: 80px;
  text-align: center;
  background-color: #f36525;
  margin: 0 auto;
}

.sigma_img-box{
  display: flex;
  flex-wrap: wrap;
}

.sigma_img-box img + img{
  margin-top: 40px;
  margin-left: 30px;
}

.section-before{
  position: absolute;
  top: 0;
  left: 0;
  width: 30%;
  height: 100%;
  z-index: -1;
  background-color: #313131;
}
.section-before::after{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.6);
}

.sigma_dots{
  width: 200px;
  height: 200px;
  content: '';
  background-position: 0 0,6px 6px;
  background-size: 12px 12px;
  position: absolute;
  display: block;
  background-image: -o-radial-gradient(#d1d4d8 1px,transparent 1px),-o-radial-gradient(#d1d4d8 1px,transparent 1px);
  background-image: radial-gradient(#d1d4d8 1px,transparent 1px),radial-gradient(#d1d4d8 1px,transparent 1px);
  -webkit-transition: opacity .3s;
  -o-transition: opacity .3s;
  transition: opacity .3s;
  background-color: transparent;
  top: -250px;
  left: -100px;
  opacity: 1;
  z-index: -1;
}
.sigma_dots.dots-2{
  width: 100px;
  left: auto;
  height: 200px;
  right: 0;
  top: 100%;
}
.sigma_dots.primary{
  background-image: -o-radial-gradient(#f36525 1px,transparent 1px),-o-radial-gradient(#f36525 1px,transparent 1px);
  background-image: radial-gradient(#f36525 1px,transparent 1px),radial-gradient(#f36525 1px,transparent 1px);
}
.sigma_dots.secondary{
  background-image: -o-radial-gradient(#313131 1px,transparent 1px),-o-radial-gradient(#313131 1px,transparent 1px);
  background-image: radial-gradient(#313131 1px,transparent 1px),radial-gradient(#313131 1px,transparent 1px);
}

@keyframes circlesUpDown {
  0%{
    transform: translateY(-45%);
  }
  50%{
    transform: translateY(-50%);
  }
  100%{
    transform: translateY(-45%);
  }
}
@keyframes circlesUpDown2 {
  0%{
    bottom: -10px;

  }
  50%{
    bottom: 0;

  }
  100%{
    bottom: -10px;
  }
}

.bottom-skew{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.circle{
  position: absolute;
  bottom: -10px;
  right: -5px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: -1;
  animation-name: circlesUpDown2;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

.circles{
  position: absolute;
  top: 50%;
  right: -175px;
  transform: translateY(-45%);
  z-index: -1;
  animation-name: circlesUpDown;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

.circles .circle-2{
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background-color: #f8f8f8;
}
.circles .circle-3{
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.circles .circle-4{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #f36525;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.circles.outline{
  right: auto;
}
.circles.outline .circle-2{
  background-color: transparent;
  border: 1px solid #efefef;
}
.circles.outline .circle-3{
  background-color: transparent;
  border: 1px solid #fff;
}
.circles.outline .circle-4{
  background-color: transparent;
  border: 1px solid #f36525;
}

.circle-container {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  padding: 0;
  list-style: none;
  border: 2px solid #efefef;
}
.circle-container-in{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid #efefef;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.circle-container-in img{
  width: 100px;
}
.circle-container > ul li {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -3em;
  width: 6em;
  height: 6em;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .3s;
  border: 5px solid #fff;
}
.circle-container > ul li i{
  font-size: 34px;
  line-height: 0;
}
.circle-container > ul li:hover{
  background-color: #f36525;
  color: #fff;
}
.circle-container > ul li:nth-of-type(1) {
  -webkit-transform: rotate(0deg) translate(17em) rotate(0deg);
          transform: rotate(0deg) translate(17em) rotate(0deg);
}
.circle-container > ul li:nth-of-type(2) {
  -webkit-transform: rotate(60deg) translate(17em) rotate(-60deg);
          transform: rotate(60deg) translate(17em) rotate(-60deg);
}
.circle-container > ul li:nth-of-type(3) {
  -webkit-transform: rotate(120deg) translate(17em) rotate(-120deg);
          transform: rotate(120deg) translate(17em) rotate(-120deg);
}
.circle-container > ul li:nth-of-type(4) {
  -webkit-transform: rotate(180deg) translate(17em) rotate(-180deg);
          transform: rotate(180deg) translate(17em) rotate(-180deg);
}
.circle-container > ul li:nth-of-type(5) {
  -webkit-transform: rotate(240deg) translate(17em) rotate(-240deg);
          transform: rotate(240deg) translate(17em) rotate(-240deg);
}
.circle-container > ul li:nth-of-type(6) {
  -webkit-transform: rotate(300deg) translate(17em) rotate(-300deg);
          transform: rotate(300deg) translate(17em) rotate(-300deg);
}

.custom-bg{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
}
.custom-img-1{
  position: absolute;
  bottom: -210px;
  right: 0;
  z-index: -3;
}

.spacer{
  height: 90px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
}
.spacer.spacer-lg{
  height: 365px;
}
.spacer.spacer-md{
  height: 195px;
}
.spacer.spacer-xl{
  height: 500px;
}
.spacer.spacer-bottom{
  top: auto;
  bottom: 0;
}

.spacer.spacer-vertical{
  height: 100%;
  width:  140px;
}

.spacer.spacer-vertical.spacer-right{
  right: 0;
  left: auto;
}
.spacer.spacer-vertical.spacer-left{
  right: auto;
  left: 0;
}

.spacer.spacer-vertical.spacer-xl{
  width: 500px;
}

.sigma_canvas canvas,
.sigma_canvas{
  width: 100%;
}
.sigma_map{
  height: 600px;
  width: 100%;
}
.sigma_map .sigma_contact-info{
  left: auto;
  right: 0;
}
.sigma_map iframe{
  width: 100%;
  height: 100%;
}
.sigma_seperator-vertical{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.sigma_seperator-vertical span{
  width: 1px;
  height: 200px;
  background-color: #efefef;
  display: block;
}
.sigma_seperator-vertical span:first-child,
.sigma_seperator-vertical span:last-child{
  margin: 0 20px;
}
.sigma_seperator-vertical span:nth-child(2){
  height: 400px;
}

.stroke-text{
  color: #fff;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  font-weight: 400;
}

.stroke-text.stroke-text-dark{
  -webkit-text-stroke-color: #313131;
}

.stroke-text span{
  -webkit-text-fill-color: #fff;
  -webkit-text-stroke-width: 0;
}
.stroke-text span.custom-primary{
  -webkit-text-fill-color: #f36525;
  -webkit-text-stroke-width: 0;
}
.stroke-text span.text-dark{
  -webkit-text-fill-color: #313131;
  -webkit-text-stroke-width: 0;
}

.sigma_preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    transition: .3s;
}

.sigma_preloader.hidden{
  opacity: 0;
  visibility: hidden;
}

.sigma_preloader ul {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  -webkit-animation: rot 16s linear infinite;
          animation: rot 16s linear infinite;
}
.sigma_preloader li {
  width: 40px;
  height: 40px;
  background: #f36525;
  border-radius: 4px;
  box-shadow: 0 0 1px #fff, 0 0 5px #f36525, 0 0 10px #f36525, 0 0 15px #f36525, 0 0 25px #f36525, 0 0 55px #f36525;
  -webkit-animation: preloaderScale 0.8s linear alternate infinite;
          animation: preloaderScale 0.8s linear alternate infinite;
}

.sigma_preloader li:nth-child(1) {
  z-index: 24;
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}
.sigma_preloader li:nth-child(2) {
  z-index: 23;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
.sigma_preloader li:nth-child(3) {
  z-index: 22;
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.sigma_preloader li:nth-child(4) {
  z-index: 21;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.sigma_preloader li:nth-child(5) {
  z-index: 20;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.sigma_preloader li:nth-child(6) {
  z-index: 19;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
.sigma_preloader li:nth-child(7) {
  z-index: 18;
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.sigma_preloader li:nth-child(8) {
  z-index: 17;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.sigma_preloader li:nth-child(9) {
  z-index: 16;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.sigma_preloader li:nth-child(10) {
  z-index: 15;
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
.sigma_preloader li:nth-child(11) {
  z-index: 14;
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.sigma_preloader li:nth-child(12) {
  z-index: 13;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.sigma_preloader li:nth-child(13) {
  z-index: 12;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.sigma_preloader li:nth-child(14) {
  z-index: 11;
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
.sigma_preloader li:nth-child(15) {
  z-index: 10;
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}
.sigma_preloader li:nth-child(16) {
  z-index: 9;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.sigma_preloader li:nth-child(17) {
  z-index: 8;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.sigma_preloader li:nth-child(18) {
  z-index: 7;
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
.sigma_preloader li:nth-child(19) {
  z-index: 6;
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}
.sigma_preloader li:nth-child(20) {
  z-index: 5;
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}
.sigma_preloader li:nth-child(21) {
  z-index: 4;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.sigma_preloader li:nth-child(22) {
  z-index: 3;
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
.sigma_preloader li:nth-child(23) {
  z-index: 2;
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}
.sigma_preloader li:nth-child(24) {
  z-index: 1;
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}
.sigma_preloader li:nth-child(25) {
  z-index: 0;
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}

.spinner {
    width: 40px;
    height: 40px;
    position: relative;
    text-align: center;

    -webkit-animation: sk-rotate 2.0s infinite linear;
    animation: sk-rotate 2.0s infinite linear;
}

.dot1,
.dot2 {
    width: 60%;
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: #f36525;
    border-radius: 100%;

    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
}

.dot2 {
    top: auto;
    bottom: 0;
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
}

.opacity-0 {
    opacity: 0;
}

.opacity-25 {
    opacity: .25;
}

.opacity-50 {
    opacity: .50;
}

.opacity-75 {
    opacity: .75;
}

.opacity-100 {
    opacity: 1;
}

.pattern-building,
.pattern-squares,
.pattern-map,
.primary-overlay,
.light-overlay,
.secondary-overlay,
.dark-overlay {
    position: relative;
    z-index: 1;
}
.pattern-building::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url(../img/textures/building.png);
  background-position: 500% 100%;
  background-repeat: no-repeat;
}
.pattern-map::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url(../img/textures/map-2.png);
  background-size: cover;
  background-position: center;
}
.pattern-squares::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url(../img/textures/squares.png);
  background-size: cover;
  background-position: center;
}
.pattern-triangles::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url(../img/textures/triangles.png);
  background-size: cover;
  background-position: center;
}
.dark-overlay::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(49, 49, 49, .4);
  z-index: -1;
  -webkit-transition: background-color .3s;
  -o-transition: background-color .3s;
  transition: background-color .3s;
}
.light-overlay::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255, .4);
    z-index: -1;
    -webkit-transition: background-color .3s;
    -o-transition: background-color .3s;
    transition: background-color .3s;
}
.primary-overlay::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f36525;
    opacity: .4;
    z-index: -1;
    -webkit-transition: background-color .3s;
    -o-transition: background-color .3s;
    transition: background-color .3s;
}
.secondary-overlay::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #313130;
    opacity: .8;
    z-index: -1;
    -webkit-transition: background-color .3s;
    -o-transition: background-color .3s;
    transition: background-color .3s;
}


.dark-overlay.primary-overlay::before {
  background-color: rgba(72, 172, 110, .8);
}

.dark-overlay.dark-overlay-2::before {
    background-color: rgba(49, 49, 49, .6);
}
.dark-overlay.dark-overlay-3::before {
    background-color: rgba(49, 49, 49, .8);
}

.sigma_sticky-section {
    position: sticky;
    top: 30px;
}

html {
    overflow-x: hidden;
}

body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    font-size: 16px;
    line-height: 1.8;
    font-weight: 400;
    color: #777;
    overflow-x: hidden;
}

hr {
    margin: 30px 0;
}

img {
    max-width: 100%;
    height: auto;
}

.imgs-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.imgs-wrapper img {
    position: absolute;
    max-width: 300px;
}

.imgs-wrapper img:nth-child(1) {
    top: 150px;
    max-width: 400px;
    left: -200px;
}

.imgs-wrapper img:nth-child(2) {
    right: -100px;
    bottom: 0;
}

.sigma_single-img-wrapper {
    position: relative;
}

.sigma_single-img-wrapper img {
    border-radius: 8px;
}

.signature {
    width: 220px;
    margin-bottom: 20px;
}

.cursor-pointer {
    cursor: pointer;
}

.close-btn {
    border: 0;
    background-color: transparent;
    padding: 0;
    width: 30px;
    height: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    transition: .3s;
    outline: none;
}

.close-btn:focus {
    outline: none;
}

.close-btn span {
    position: absolute;
    width: 2px;
    height: 15px;
    display: block;
    background-color: #fff;
    opacity: 0.6;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.close-btn.close-dark span {
    background-color: #313131;
}

.close-btn.close-danger span {
    background-color: red;
}

.close-btn span:nth-child(2) {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.close-btn:hover {
  transform: rotate(45deg);
}

.close-btn.dark span {
    background-color: #313131;
}

.icon-2x {
    font-size: 2rem;
}

.icon-3x {
    font-size: 3rem;
}

.icon-4x {
    font-size: 4rem;
}

.border-0 {
    border: 0;
}

.bg-cover {
    background-size: cover;
}

.bg-lg {
    background-size: 600px;
}

.bg-parallax {
    background-attachment: fixed;
}

.bg-contain {
    background-size: contain;
}

.bg-norepeat {
    background-repeat: no-repeat;
}

.bg-center {
    background-position: center;
}

.bg-left {
    background-position: left;
}

.bg-right {
    background-position: right;
}

.bg-top {
    background-position: top;
}

.bg-bottom {
    background-position: bottom;
}
.bg-bottom-left {
    background-position: bottom left;
}
.bg-bottom-right {
    background-position: bottom right;
}

.container-fluid{
  padding-left: 150px;
  padding-right: 150px;
}

.sigma_menu-vertical{
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

.sigma_menu-vertical li,
.sigma_menu-vertical li + li{
  margin: 0;
}
.sigma_menu-vertical li a{
  padding: 40px 20px;
  display: block;
  color: #777777;
}
.sigma_menu-vertical li a:hover{
  color: #fff;
}

/*======================
1.1. Typography
========================*/

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto Slab", serif;
  margin: 0 0 20px;
  line-height: 1.2;
  color: #313131;
  font-weight: 700;
}

h1 {
  font-size: 52px;
}

h2 {
  font-size: 44px;
}

h3 {
  font-size: 36px;
  line-height: 1.2;
}

h4 {
  font-size: 30px;
  line-height: 1.4;
}

h5 {
  font-size: 26px;
  line-height: 1.3;
}

h6 {
  font-size: 18px;
  line-height: 1.7;
}

p {
  font-size: 16px;
  line-height: 1.8;
  margin-bottom: 15px;
  color: #777;
}

a {
  color: #f36525;
  text-decoration: none;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: #313131;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
h1 a:focus,
h2 a:focus,
h3 a:focus,
h4 a:focus,
h5 a:focus,
h6 a:focus {
  color: #e14702;
}

a:hover,
a:focus {
    color: #e14702;
    text-decoration: none;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    outline: none;
}

b,
strong {
    font-weight: 700;
    color: #313131;
}

b span,
strong span{
  color: #777;
}

label {
    margin-bottom: 10px;
    color: #313131;
    font-weight: 600;
}

label.error{
  color: #ff0000;
  display: block;
  font-size: 13px;
  font-weight: normal;
  text-align: left;
}

ol,
ul {
    list-style: none;
    padding: 0;
    margin-top: 0;
    margin-bottom: 20px;
}

ul li,
ol li {
    margin: 10px 0 0;
    position: relative;
}
.sigma_list{
  margin: 40px 0 40px;
}
.sigma_list.list-2{
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

.sigma_list li {
  margin: 0;
  display: flex;
  align-items: center;
  position: relative;
  color: #313131;
  font-weight: 600;
}

.sigma_list li+li {
    margin-top: 20px;
}

.sigma_list li::before {
  font-family: "Font Awesome 5 Pro";
  content: "\f00c";
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  text-rendering: auto;
  line-height: 1;
  font-size: 20px;
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  border-radius: 50%;
  border: 1px solid #efefef;
  margin-right: 10px;
}

.sigma_list.sigma_list-2 li{
  font-weight: 400;
}

.small,
small {
  line-height: 1.7;
}

.blockquote,
blockquote {
  position: relative;
  margin: 20px 0 30px;
  color: #fff;
  padding: 10px 20px;
  background-position: center;
  background-size: cover;
  background-color: #313131;
  border-radius: 35px;
}

.blockquote.light-border,
blockquote.light-border{
  border-left-color: rgba(255,255,255,.4);
}

blockquote{
  border-left: 0;
  text-align: center;
  padding: 50px;
}

.entry-content blockquote p,
blockquote p {
  position: relative;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 700;
  color: #fff;
}

blockquote cite{
  position: relative;
  font-weight: 600;
  color: #f36525;
  display: block;
  margin: 0 0 10px;
}

.blockquote::before,
blockquote::before{
  position: absolute;
  bottom: 0;
  right: 10px;
  font-size: 160px;
  content: "\f10e";
  color: #fff;
  opacity: .1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
.blockquote{
  display: flex;
  align-items: flex-start;
  padding: 40px;
}
.blockquote.border-0{
  border-radius: 0;
}
.sigma_blockquote-content{
  flex: 1;
  margin-left: 25px;
}
.sigma_blockquote-content p{
  color: #d2d2d2;
  font-size: 14px;
}
.sigma_blockquote-content h6{
  margin: 0;
}
.sigma_blockquote-content span{
  font-size: 13px;
}
.sigma_avatar{
  width: 100px;
  border-radius: 50%;
  position: relative;
}
.sigma_avatar img{
  width: 100px;
  border-radius: 50%;
}
.sigma_avatar i{
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f36525;
  color: #fff;
  font-size: 12px;
  border-radius: 50%;
  border: 1px solid #efefef;
}
.entry-content p,
.entry-content img {
    margin-bottom: 20px;
}

.entry-content .sigma_post-share {
    margin-bottom: 20px;
}

.entry-content p a{
  font-weight: 600;
  text-decoration: underline;
}

.fs-10 {
    font-size: 10px;
}

.fs-12 {
    font-size: 12px;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-15 {
    font-size: 14px;
}

.fs-16 {
    font-size: 16px;
}

.fs-18 {
    font-size: 18px;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.border-25 {
    border-radius: 35px;
}

.sigma_image-fit {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.transform-center {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    z-index: 1;
}

/*======================
1.2 Section Spacings
========================*/
.section {
    position: relative;
    padding: 110px 0;
}
.section .container{
  position: relative;
}

.section.section-padding {
    padding: 110px 0 80px;
}
.section.section-lg{
  padding: 110px 0 200px;
}
.section.section-md{
  padding: 110px 0 160px;
}


.sigma_section-fw {
    padding: 0 80px;
}

.mb-30 {
  margin-bottom: 30px;
}
.mb-50{
  margin-bottom: 50px;
}

.mr-lg-30 {
    margin-left: 30px;
}

.section.section-padding.category_section {
    margin-top: -50px;
    padding-top: 0;
}
.section-title .badge{
  margin-bottom: 10px;
}

.section-title .subtitle {
  position: relative;
  margin-bottom: 15px;
  font-size: 16px;
  color: #f36525;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}
.section-title.text-left .subtitle{
    justify-content: flex-start;
}

.section-title.text-md-right .subtitle,
.section-title.text-right .subtitle{
  justify-content: flex-end;
}

.section-title.text-md-right,
.section-title.text-right{
  margin: 0 0 50px auto;
}

.section-title p{
  font-size: 16px;
}

.section-title.section-title-2{
  position: relative;
}
.section-title.section-title-2 .subtitle{
  color: #f36525;
  background-color: #fff8e6;
  display: inline-block;
  padding: 5px 20px;
  font-size: 13px;
}
.section-title.section-title-2 .subtitle.light{
  background-color: rgba(255,255,255,.4);
}

.small-text{
  letter-spacing: 3px;
  font-size: 16px;
}

.section-title .title {
  position: relative;
  margin: 0;
  font-size: 46px;
  line-height: 1.2;
  margin-bottom: 15px;
  font-weight: 700;
}
.section-title.sm .title{
  font-size: 32px;
}

.section-title {
    position: relative;
    margin-bottom: 50px;
    max-width: 550px;
}
.section-title.text-center{
  margin: 0 auto 50px;
}

.section-title.flex-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 100%;
}

.section-title.flex-title .nav {
    margin-left: auto;
}

.section-title.flex-title .nav-item {
    margin: 0;
}

.section-title.flex-title .nav-item+.nav-item {
    margin-left: 10px;
}

.section-title.flex-title .nav-item .nav-link {
    margin: 0;
}

.section-button{
  margin-top: 30px;
}

.ov-hidden {
    overflow: hidden;
}

/*==================
1.3. Forms
====================*/
textarea {
  resize: none;
}
textarea.form-control{
  border-radius: 35px;
  padding: 15px;
}

.form-control::-webkit-input-placeholder {
    color: #989898;
}

.form-control::-moz-placeholder {
    color: #989898;
}

.form-control:-ms-input-placeholder {
    color: #989898;
}

.form-control::-ms-input-placeholder {
    color: #989898;
}

.form-control::placeholder {
    color: #989898;
}

.form-control {
  height: 60px;
  padding: 8px 15px;
  border-radius: 0;
  border: 0;
  border-radius: 0;
  width: 100%;
  font-size: 14px;
  color: #777;
  background-color: #fff;
  border: 1px solid #efefef;
  letter-spacing: 0.1px;
  border-radius: 55px;
}

select,
select.form-control{
  -moz-appearance: none;
  -webkit-appearance: none;
  padding-right: 1.25EM;
  appearance: none;
  position: relative;
  background-color: transparent;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' height='10px' width='15px'%3E%3Ctext x='0' y='10' fill='gray'%3E%E2%96%BE%3C/text%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 1.5EM 1EM;
  background-position: right center;
  background-clip: border-box;
  -moz-background-clip: border-box;
  -webkit-background-clip: border-box;
  padding: 8px 35px 8px 15px;
}


.form-control.transparent{
  background-color: rgba(255,255,255,.1);
  border: 0;
}
.form-control.transparent:focus{
  background-color: rgba(255,255,255,.2);
  border: 0;
}

.form-control.dark{
  background-color: #f8f8f8;
  border: 0;
}
.form-control.dark:focus{
  background-color: #eaeaea;
  border: 0;
}

.form-control:focus {
    border: 1px solid #efefef;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.form-group {
  position: relative;
  margin-bottom: 20px;
}
.form-group i{
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  color: #e8e8e8;
}

.input-with-icon {
    position: relative;
}

.input-with-icon input,
.input-with-icon select {
    padding-left: 40px;
}

.input-with-icon i {
    position: absolute;
    top: 50%;
    left: 15px;
    font-size: 18px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #a5a5a5;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.input-with-icon input:focus+i,
.input-with-icon select:focus+i {
    color: #454545;
}

.custom-control-input:focus~.custom-control-label::before {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.custom-radio .custom-control-input:checked~.custom-control-label::before,
.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: #f36525;
}

.custom-control label {
    font-weight: 400;
}
.custom-form {
  padding: 40px 40px 10px;
  background-color: #313131;
}
.custom-form .form-control{
  background-color: transparent;
  border: 2px solid #383838;
  color: #fff;
}
.custom-form .form-group i{
  color: #fff;
  height: 100%;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  border-left: 2px solid #383838;
}

/*==================
1.4. Buttons
====================*/
.btn-link {
  position: relative;
  color: #777;
  font-weight: 700;
  font-style: normal;
  text-decoration: underline;
  cursor: pointer;
}
.btn-link.h1{
  font-size: 2.5rem;
  max-width: 230px;
  display: block;
}

.btn-link:hover {
  color: #313131;
}
.btn-link i{
  transition: .3s;
  margin-left: 5px;
}
.btn-link:hover i{
  transform: translateX(5px);
}

.input-group-text {
  color: #fff;
  padding: 5px 20px;
  font-size: 22px;
  background-color: #f36525;
  border: 1px solid #f36525;
}

.sigma_btn-custom {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    position: relative;
    background-color: #f36525;
    border: 0;
    color: #fff;
    border-radius: 35px;
    padding: 15px 30px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    font-size: 16px;
    font-weight: 600;
    overflow: hidden;
    z-index: 1;
}

.sigma_btn-custom::before{
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center;
  background-color: #e14702;
  width: 10px;
  height: calc(100% - 15px);
  z-index: -1;
  border-radius: 35px;
  transition: .3s;
  opacity: 0;
  visibility: hidden;
}

.sigma_btn-custom:focus::before,
.sigma_btn-custom:hover::before{
  width: calc(100% - 14px);
  opacity: 1;
  visibility: visible;
}

.sigma_btn-custom:hover,
.sigma_btn-custom:focus {
    color: #fff;
    outline: none;
}
.sigma_btn-custom.primary {
    background-color: #f36525;
}

.sigma_btn-custom.transparent{
  background-color: transparent;
  border: 1px solid transparent;
  color: #313131;
}
.sigma_btn-custom.transparent::before{
  background-color: #e14702;
}
.sigma_btn-custom.transparent:hover,
.sigma_btn-custom.transparent:focus{
  color: #fff;
  border: 1px solid #efefef;
}

.sigma_btn-custom.primary::before {
  background-color: #e14702;
}

.sigma_btn-custom.dark{
  background-color: #313131;
  color: #fff;
  box-shadow: none;
}
.sigma_btn-custom.dark::before{
  background-color: #353535;
}
.sigma_btn-custom.light:hover,
.sigma_btn-custom.light:focus,
.sigma_btn-custom.dark:hover,
.sigma_btn-custom.dark:focus{
  color: #fff;
}
.sigma_btn-custom.light {
    background-color: transparent;
    color: #313131;
    box-shadow: none;
    border: 2px solid #efefef;
}

.sigma_btn-custom.light::before {
    background-color: #313130;
}
.sigma_btn-custom.white {
    background-color: #fff;
    color: #313131;
    box-shadow: none;
}
.sigma_btn-custom.white::before {
    background-color: #efefef;
}

.sigma_btn-custom.secondary {
    background-color: #313130;
    color: #fff;
}

.sigma_btn-custom.secondary::before{
    background-color: #353535;
}

.sigma_btn-custom.btn-sm {
    padding: 12px 26px;
}
.sigma_btn-custom.btn-pill{
  border-radius: 55px;
}

.sigma_btn-custom i{
  margin-left: 10px;
}

.btn-group .sigma_btn-custom+.sigma_btn-custom {
    padding: 12px 14px;
}

.btn-group .sigma_btn-custom.btn-sm+.sigma_btn-custom.btn-sm {
    padding: 12px 16px;
}

.input-group-append .sigma_btn-custom,
.input-group-append .sigma_btn-custom::before {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.btn-group .sigma_btn-custom.dropdown-toggle.dropdown-toggle-split:hover::after {
    animation-name: BounceUpDown;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: both;
    animation-timing-function: ease-out;
}

.btn-group.dropup .sigma_btn-custom.dropdown-toggle.dropdown-toggle-split:hover::after {
    animation-name: BounceDownUp;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: both;
    animation-timing-function: ease-out;
}

.sigma_round-button{
  position: relative;
  display: inline-block;
}

.sigma_round-button > span{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  font-size: 28px;
  line-height: 1;
  color: #313131;
}

.sigma_round-button:hover{
  color: #777;
}
.sigma_round-button svg{
  width: 120px;
  height: 120px;
  overflow: visible;
}
.sidebar-widget .sigma_round-button svg{
  width: 40px;
  height: 40px;
}
.sigma_round-button.sm svg{
  width: 80px;
  height: 80px;
}

.sigma_round-button i{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.sigma_round-button .sigma_round-button-stroke{
  fill: none;
  stroke: #efefef;
  stroke-width: 10;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  position: relative;
  z-index: -1;
  color: #efefef;
}
.sigma_round-button .sigma_round-button-circle{
  fill: none;
  stroke: #f36525;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  stroke-dashoffset: 700;
  stroke-dasharray: 700;
  stroke-width: 20px;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  stroke-linecap: butt;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  transition: stroke-dashoffset 1s;
  color: #f36525;
  stroke-linecap: round;
}

.sigma_round-button .sigma_round-button-circle.secondary{
  color: #313130;
  stroke: #313130;
}

.sigma_round-button.sm svg{
  width: 100px;
  height: 100px;
}
.sigma_round-button.sm > span{
  font-size: 22px;
}
.sigma_round-button.sm > span span{
  font-size: 12px;
}

/*==================
1.5. Colors & Badges
====================*/
.custom-primary {
  color: #f36525 !important;
}
.custom-secondary {
  color: #313130;
}
.custom-body {
  color: #777;
}
.custom-dark{
  color: #d2d2d2;
}

.twitter {
    background-color: rgb(44, 170, 225) !important;
    color: #fff;
}

.twitter:hover {
    background-color: #2caae1;
    color: #fff;
}

.facebook {
    background-color: rgb(59, 87, 157) !important;
    color: #fff;
}

.facebook:hover {
    background-color: #3b579d;
    color: #fff;
}

.google {
    background-color: rgb(220, 74, 56) !important;
    color: #fff;
}

.google:hover {
    background-color: #dc4a38;
    color: #fff;
}

.linkedin {
    background-color: rgb(1, 119, 181) !important;
    color: #fff;
}

.linkedin:hover {
    background-color: #0177b5;
    color: #fff;
}

.pinterest {
    background-color: rgb(204, 33, 39) !important;
    color: #fff;
}

.pinterest:hover {
    background-color: #cc2127;
    color: #fff;
}

.youtube {
    background-color: rgb(229, 45, 39);
    color: #fff;
}

.youtube:hover {
    background-color: #e52d27;
    color: #fff;
}

.github {
    background-color: rgb(51, 51, 51) !important;
    color: #fff;
}

.github:hover {
    background-color: #333333 !important;
    color: #fff;
}

.behance {
    background-color: rgb(23, 105, 255) !important;
    color: #fff;
}

.behance:hover {
    background-color: #1769ff;
    color: #fff;
}

.dribbble {
    background-color: rgb(234, 76, 137) !important;
    color: #fff;
}

.dribbble:hover {
    background-color: #ea4c89;
    color: #fff;
}

.reddit {
    background-color: rgb(255, 69, 0) !important;
    color: #fff;
}

.reddit:hover {
    background-color: #ff4500;
    color: #fff;
}

.white-bg{
  background-color: #ffffff;
}
.light-bg {
    background-color: #f8f8f8;
}
.dark-bg{
  background-color: #313131;
}
.dark-bg-2{
  background-color: #313131;
}
.border-bottom-dark{
  border-bottom: 1px solid #2d2d2d;
}

.primary-bg {
    background-color: #f36525;
}
.primary-dark-bg{
  background-color: #e14702;
}

.primary-light-bg{
  background-color: #fff8e6;
}

.secondary-bg {
    background-color: #313130;
}

.sigma_img-custom{
  position: relative;
  z-index: 1;
}
.sigma_img-custom img:first-child{
  max-width: 70%;
}
.sigma_img-custom img:last-child{
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.badge{
  background-color: #fff8e6;
  padding: 6px 11px;
  font-size: 18px;
  border-radius: 0;
  display: inline-block;
  font-weight: 400;
  line-height: 1.2;
}
.badge.badge-primary{
  color: #f36525;
  background-color: #fff8e6;
}
.badge.badge-secondary{
  color: #fff;
  background-color: #313130;
}

/*=============================
1.6 Modal, Popups & Dropdowns
==============================*/
.modal-header .close {
    background-color: #020202;
    opacity: 1;
    margin: 0;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    padding: 0;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-shadow: none;
}

.modal-header {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.modal-header .close:not(:disabled):not(.disabled):hover {
    background-color: #dd3333;
    opacity: 1;
}

.modal-lg {
    max-width: 1000px;
}

.modal-content {
    border-radius: 0;
    border: 0;
}

.modal-body {
    padding: 40px;
}

.dropdown-menu {
    padding: 10px;
    color: #777;
    border: 1px solid #efefef;
    box-shadow: 0 4px 24px rgba(0, 0, 0, .1);
}

.dropdown-item {
    padding: 6px 10px;
    font-weight: 600;
    color: #777;
    border-radius: 8px;
}

.dropdown-item i {
    display: inline-block;
    width: 25px;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: #f8f9fa;
    color: #16181b;
}

/*======================
1.7 Tables
========================*/
table{
  margin-bottom: 30px;
}
table th,
table td {
    padding: 15px;
    vertical-align: middle;
    background-color: #fff;
}

table th {
    font-weight: 700;
    color: #313131;
    font-size: 16px;
    border: 1px solid #efefef;
}

table {
    width: 100%;
    margin-bottom: 30px;
}

table td {
    border: 1px solid #efefef;
    font-size: 14px;
}

table img {
    width: 40px;
    border-radius: 0;
}

.thead-dark tr th{
  background-color: #313131;
  color: #fff;
  border-color: #32383e;
}

/*======================
1.8 Tabs
========================*/
.tab-content {
    margin-top: 20px;
}

.nav-item {
  margin: 0;
}
.nav-tabs{
  border-bottom: 0;
}
.nav-tabs .nav-link{
  padding: 10px 20px;
  font-weight: 600;
  color: #313131;
  background-color: #f8f8f8;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover{
  color: #f36525;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover,
.nav-tabs .nav-link{
  border: 0;
  border-radius: 0;
}
.nav-tabs .nav-item + .nav-item{
  margin-left: 5px;
}
.nav-tabs .nav-link i{
  margin-right: 5px;
  color: #f36525;
}
.sigma_product-additional-info .nav-link.active,
.nav-link.active {
  font-weight: 600;
  color: #f36525;
  border-bottom-color: #f36525;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active{
  border-bottom: 2px solid #f36525;
  font-weight: 600;
}

.nav-tabs.tabs-2{
  background-color: #f8f8f8;
  display: inline-flex;
  padding: 10px;
  border-radius: 55px;
}
.nav-tabs.tabs-2 .nav-item .nav-link{
  border-radius: 35px;
}
.nav-tabs.tabs-2 .nav-item.show .nav-link,
.nav-tabs.tabs-2 .nav-link.active{
  background-color: #f36525;
  color: #fff;
  border: 0;
}

/*======================
1.9. Social media links
========================*/
.sigma_sm {
    display: flex;
    align-items: center;
    margin: 0;
}

.sigma_sm li {
    margin: 0;
}

.sigma_sm li a {
    display: inline-block;
}

.sigma_sm li+li {
    margin-left: 15px;
}

.sigma_sm.square li a{
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  background-color: rgba(255,255,255,.1);
  color: #fff;
  border-radius: 50%;
}

.sigma_sm.square.light li a{
  background-color: #f8f8f8;
  color: #313131;
}

.sigma_sm.square.light li a:hover,
.sigma_sm.square li a:hover{
  background-color: #f36525;
  color: #fff;
}

/*======================
2. Header
========================*/

.sigma_header.header-fw .sigma_header-top > .container,
.sigma_header.header-fw .sigma_header-middle > .container,
.sigma_header.header-fw .sigma_header-bottom > .container{
  width: 100%;
  max-width: 100%;
  padding-left: 4vw;
  padding-right: 4vw;
}
.sigma_header.header-fw.header-2 .sigma_header-middle > .container{
  padding-left: 0;
}

@keyframes fadeHeaderInDown {
  0% {
    opacity:0;
    transform:translate3d(0, -100%, 0);
  }
  100% {
    opacity:1;
    transform:translate3d(0, 0, 0);
  }
}

.sigma_header.sticky.header-1 .sigma_header-middle{
  position: fixed;
  top: -100%;
  width: 100%;
  z-index: 990;
  -webkit-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.sigma_header.header-1.sticky .sigma_header-middle{
  top: 0;
  box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
  animation-name:fadeHeaderInDown;
}
.sigma_header.header-1.sticky:not(.header-light) .sigma_header-middle{
  background-color: #fff;
}

.sigma_header.header-1.sticky .sigma_header-middle .navbar>.navbar-nav>.menu-item>a:hover{
  color: #f36525;
}

.header-absolute {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}
.header-absolute .sigma_header-middle{
  background-color: rgba(0,0,0,.4);
}

.header-absolute.sigma_header{
  padding: 0;
}

.sigma_header.header-1.sticky .sigma_header-middle {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 190;
    box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
    background-color: #fff;
}
.sigma_header.header-1.sticky .aside-toggler span{
  background-color: #fff;
}
.sigma_header.header-1.sticky .aside-toggler:hover span{
  background-color: #f36525;
}
.sigma_header.header-1.sticky .sigma_header-controls-inner li a i,
.sigma_header.header-1.sticky.header-light .sigma_header-controls-inner li a i{
  color: #fff;
}
.sigma_header.header-1.sticky .aside-toggler.desktop-toggler span,
.sigma_header.header-1.sticky.header-light .aside-toggler.desktop-toggler span{
  background-color: #fff;
}

.sigma_header-top {
  background-color: #313131;
  padding: 0;
}

.sigma_header-top .sigma_sm li+li{
  margin-left: 20px;
}

.sigma_header-top-inner {
    display: flex;
}

.sigma_header-top-inner .sigma_sm{
  margin-left: auto;
}
.sigma_header-top-inner .sigma_sm li a{
  color: #d2d2d2;
}
.sigma_header-top-inner .sigma_sm li a:hover{
  color: #f36525;
}

.sigma_header-top-links {
    display: flex;
    align-items: center;
    margin: 0;
}
.sigma_header-top-links + .sigma_header-top-links{
  margin-left: auto;
}

.sigma_header-top-links li {
    margin: 0;
}
.sigma_header-top-links li a i{
  margin-right: 10px;
}
.sigma_header-top-links > li + li > a{
  padding-left: 15px;
  margin-left: 15px;
  border-left: 1px solid #313131;
}

.sigma_header-top-sm li a,
.sigma_header-top-links a {
    color: #d2d2d2;
}

.sigma_header-top-sm li a:hover,
.sigma_header-top-links a:hover {
    color: #f36525;
}

.sigma_header-top-links a {
    font-weight: 400;
    font-size: 13px;
    padding: 15px 0;
    line-height: normal;
    display: flex;
    align-items: center;
}

.sigma_header-top-links .sigma_account a {
    padding: 0;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin: 10px 0;
}

.sigma_header-top-cta a{
  height: 100%;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sigma_header-top-user{
  margin-left: 30px;
  max-width: 40px;
}

.sigma_header-middle {
    background-color: #fff;
}

.sigma_header-middle .navbar {
    padding: 0;
}

.navbar-brand {
    padding: 10px 0;
    width: 190px;
}

/* Logo */
.header-2 .sigma_logo-wrapper{
  position: relative;
  background-color: #f36525;
  height: 113px;
  padding: 0 30px;
  margin-bottom: -30px;
  z-index: 3;
  display: flex;
  box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
  align-items: center;
}
.header-2 .sigma_logo-wrapper .navbar-brand{
  margin-right: 0;
}

/* Controls */
.sigma_header-controls {
  display: flex;
  align-items: center;
}

.sigma_header-controls-inner {
  display: flex;
  align-items: center;
  margin: 0;
}

.sigma_header-controls-inner li {
  margin: 0;
}

.sigma_header-controls-inner li a {
  width: 58px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #efefef;
}
.sigma_header-controls-inner li a:not(.sigma_btn-custom){
  border-radius: 50%;
}

.sigma_header-controls-inner li a i {
    font-size: 18px;
    color: #313131;
}

.sigma_header-controls-inner li:last-child a {
    margin-right: 0;
}

.sigma_header-controls-inner li.sigma_header-cart a {
    width: auto;
    height: auto;
    border-radius: 8px;
    padding: 5px 15px;
    display: flex;
    align-items: center;
}

.sigma_header-controls-inner li.sigma_header-cart a i {
    font-size: 26px;
    color: #777;
}

.sigma_header-controls-inner li.sigma_header-cart .sigma_header-cart-content {
    margin-left: 15px;
}

.sigma_header-controls-inner li.sigma_header-cart .sigma_header-cart-content span {
    display: block;
    font-size: 13px;
    font-weight: 600;
    color: #313131;
}

.sigma_header-controls-inner li.sigma_header-cart .sigma_header-cart-content span+span {
    color: #f36525;
}

/* Controls Style 2 */
.sigma_header-controls.style-2 .aside-toggler.desktop-toggler,
.sigma_header-controls.style-2 .sigma_header-controls-inner li a{
  width: auto;
  height: auto;
  border: 0;
}

/* Header Bottom */
.sigma_header-bottom {
    background-color: #313131;
}

.sigma_header-bottom-inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sigma_header-bottom-inner .navbar-nav,
.sigma_header-middle .navbar-nav {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.sigma_header-bottom-inner .navbar-nav .menu-item,
.sigma_header-middle .navbar-nav .menu-item {
    margin: 0;
}

.sigma_header-bottom-inner>.navbar-nav>.menu-item>a,
.sigma_header-middle .navbar>.navbar-nav>.menu-item>a {
  position: relative;
  padding: 20px;
  color: #fff;
  display: block;
  font-size: 15px;
  font-weight: 600;
}

.sigma_header-middle .navbar>.navbar-nav>.menu-item>a {
    color: #313131;
    padding: 30px 15px;
    font-size: 14px;
}
.sigma_header-middle .navbar>.navbar-nav>.menu-item>a::before{
  content: '';
  width: 100%;
  height: 0;
  transition: .3s;
  background-color: #fff;
}
.sigma_header-middle .navbar>.navbar-nav>.menu-item>a:hover::before{
  height: 100%;
}

.sigma_header-bottom-inner>.navbar-nav>.menu-item>a:hover,
.sigma_header-middle .navbar>.navbar-nav>.menu-item>a:hover {
    color: #f36525;
}

.sigma_header-bottom-inner>.navbar-nav>.menu-item:first-child>a,
.sigma_header-middle .navbar>.navbar-nav>.menu-item:first-child>a {
    padding-left: 0;
}

.sigma_header-bottom-inner .navbar-nav .menu-item-has-children,
.sigma_header-middle .navbar-nav .menu-item-has-children {
    position: relative;
}

.sigma_header .sub-menu,
.sigma_flex-menu .sub-menu {
    position: absolute;
    top: 105%;
    left: 0;
    border-radius: 0;
    width: 100%;
    background-color: #fff;
    transition: .3s;
    opacity: 0;
    visibility: hidden;
    z-index: 998;
    min-width: 230px;
    box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
    border-radius: 25px;
}

.sigma_flex-menu>ul {
    display: flex;
    margin: 0;
    align-items: center;
}

.sigma_flex-menu ul li {
    margin: 0;
}

.sigma_flex-menu>ul>li>a {
    padding: 0 10px 20px 10px;
    display: block;
    font-weight: 600;
    color: #313131;
}

.sigma_flex-menu>ul>li>a:hover {
    color: #f36525;
}

.sigma_flex-menu>ul>li:first-child>a {
    padding-left: 0;
}

.sigma_header .sub-menu.sub-menu-left,
.sigma_flex-menu .sub-menu.sub-menu-left {
    left: auto;
    right: 0;
}

.sigma_header-middle .navbar-nav .menu-item-has-children:hover>.sub-menu,
.sigma_header-bottom-inner .navbar-nav .menu-item-has-children:hover>.sub-menu,
.sigma_search-adv-cats:hover .sub-menu,
.sigma_header-top-links .menu-item-has-children:hover .sub-menu,
.sigma_flex-menu .menu-item-has-children:hover .sub-menu {
    top: 100%;
    opacity: 1;
    visibility: visible;
}

.sigma_header .sub-menu a,
.sigma_flex-menu .sub-menu a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    color: #777;
    font-size: 14px;
    font-weight: 500;
}

.sigma_header .sub-menu a:hover,
.sigma_flex-menu .sub-menu a:hover {
  color: #f36525;
  transform: translateX(5px);
}

.sigma_header-middle .navbar-nav .menu-item-has-children .sub-menu .sub-menu,
.sigma_header-bottom-inner .navbar-nav .menu-item-has-children .sub-menu .sub-menu,
.sigma_search-adv-cats .sub-menu .sub-menu,
.sigma_header-top-links .menu-item-has-children .sub-menu .sub-menu,
.sigma_flex-menu .menu-item-has-children .sub-menu .sub-menu{
    top: 0;
    left: 100%;
}

.sigma_header .sub-menu .menu-item-has-children>a>span,
.sigma_flex-menu .sub-menu .menu-item-has-children>a>span {
    transform: rotate(-90deg);
}

.sigma_search-adv form {
    display: flex;
}

.sigma_search-adv-cats {
    position: relative;
    background-color: #f8f8f8;
    border-radius: 25px 0 0 25px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.sigma_search-adv-cats label {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    color: #777;
    font-weight: 400;
    border-bottom: 1px solid #efefef;
    cursor: pointer;
    margin: 0;
    transition: .3s;
}

.sigma_header .sub-menu label:hover {
    background-color: #f8f8f8;
}

.sigma_search-adv-cats label input {
    position: absolute;
    opacity: 0;
    visibility: hidden
}

.sigma_search-adv-cats label input+i {
    color: #30ca71;
    transition: .3s;
    visibility: hidden;
    margin-left: auto;
    opacity: 0;
    visibility: hidden;
    transform: scale(0);
    font-size: 12px;
}

.sigma_search-adv-cats label input:checked+i {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
}

.sigma_search-adv-cats>span {
    color: #313131;
    font-size: 13px;
    font-weight: 600;
}

.sidebar-widget .sigma_search-adv-input {
    width: 100%;
}

.sidebar-widget .custom-control-label {
    font-weight: 400;
}

.sigma_search-adv-input {
    position: relative;
}
.sigma_search-adv-input .form-control{
  background-color: rgba(255,255,255,.2);
  border: 0;
  color: #fff;
  box-shadow: none;
}
.sidebar-widget .sigma_search-adv-input .form-control{
  background-color: #f8f8f8;
  color: #6e6e6e;
}
.sidebar-widget .sigma_search-adv-input .form-control::placeholder{
  color: #a5a5a5;
}
.sigma_search-adv-input .form-control::placeholder{
  color: #fff;
}
.sigma_search-adv-input button {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    border: 0;
    padding: 0;
    outline: none;
    cursor: pointer;
    width: 60px;
    height: 60px;
    border-radius: 0;
    background-color: #f36525;
    color: #fff;
    transition: .3s;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}

.sigma_search-adv-input button:hover {
    color: #fff;
    background-color: #e14702;
}

.sigma_search-adv-cats .sub-menu li,
.sigma_search-adv-cats .sub-menu {
    margin: 0;
}

.sigma_search-form-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba(0,0,0,.8);
    padding: 20px;
    opacity: 0;
    visibility: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}
.sigma_search-form-wrapper.open {
  opacity: 1;
  visibility: visible;
}
.sigma_search-form-wrapper .close-btn {
  position: absolute;
  top: 60px;
  right: 60px;
}
.sigma_search-form-wrapper .close-btn span{
  height: 40px;
}
.sigma_search-form-wrapper form {
  position: relative;
  width: 70%;
  -webkit-transition-duration: .3s;
  -o-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-delay: .3s;
  -o-transition-delay: .3s;
  transition-delay: .3s;
  -webkit-transform: scale(.5);
  -ms-transform: scale(.5);
  transform: scale(.5);
  opacity: 0;
  visibility: hidden;
}
.sigma_search-form-wrapper.open form {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

.sigma_search-form-wrapper form input {
  background-color: transparent;
  border: 0;
  border-bottom: 2px solid #fff;
  padding: 20px 0;
  font-size: 40px;
  width: 100%;
  outline: none;
  color: #fff;
}
.sigma_search-form-wrapper form input::placeholder{
  color: #fff;
}
.sigma_search-form-wrapper .sigma_search-btn {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: transparent;
  border: 0;
  color: #fff;
  font-size: 30px;
  outline: none;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  cursor: pointer;
  padding: 0;
}
.sigma_header-custom-link{
  display: flex;
  align-items: center;
}
.sigma_header-custom-link i{
  font-size: 20px;
  color: #f36525;
  margin-right: 10px;
}

/*header-light*/
.header-light .sigma_header-middle .navbar>.navbar-nav>.menu-item>a{
  color: #fff;
}
.header-light.sticky .sigma_header-middle .navbar>.navbar-nav>.menu-item>a{
  color: #313131;
}

.header-light  .sigma_header-custom-link{
  color: #fff;
}

.header-light .sigma_header-controls-inner li a i{
  color: #fff;
}

.header-light .aside-toggler span,
.header-light .aside-toggler.desktop-toggler span{
  background-color: #fff;
}

.header-light .sigma_header-middle .navbar>.navbar-nav>.menu-item>a:hover,
.header-light .sigma_header-top-sm li:hover a{
  color: #f36525;
}

/* Mega Menu */
.navbar-nav .menu-item.mega-menu-wrapper {
    position: static;
}
.navbar-nav .menu-item.mega-menu-wrapper .sub-menu li > img{
  position: absolute;
  width: 410px;
  right: 20px;
  bottom: 0;
}

.navbar-nav .menu-item.mega-menu-wrapper .sub-menu {
    width: 100%;
}

.navbar-nav .menu-item.mega-menu-wrapper .sub-menu li {
    margin: 0;
    padding: 20px;
}

.mega-menu-promotion-wrapper {
    margin-top: 30px;
}

.navbar-nav .mega-menu-promotion {
    text-align: center;
}

.navbar-nav .mega-menu-promotion>a {
    display: block;
    padding: 0;
}

.navbar-nav .mega-menu-promotion img {
    width: 150px;
    margin-bottom: 5px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.navbar-nav .mega-menu-promotion:hover img {
    -webkit-transform: translateY(-4px);
    -ms-transform: translateY(-4px);
    transform: translateY(-4px);
}

.mega-menu-promotion-text h4 {
    margin-bottom: 5px;
    font-size: 18px;
}

.mega-menu-promotion-text span {
    font-weight: 600;
    line-height: 21px;
    color: #848486;
}

.navbar-nav .mega-menu-promotion-text h4 a {
    display: block;
    padding: 0;
    color: #313131;
}

.navbar-nav .mega-menu-promotion-text h4 a:hover {
    color: #ED4E53;
}

.navbar-nav .mega-menu-item h6 {
    margin-bottom: 10px;
    font-size: 24px;
}

.navbar-nav .mega-menu-item p {
    padding: 10px 0;
}

.navbar-nav .mega-menu-item a {
    display: block;
    font-weight: 400;
    padding: 10px 0;
}

.navbar-nav .mega-menu-item a:last-child {
    border-bottom: 0;
}

.navbar-nav .mega-menu-item a:hover {
    background-color: transparent;
    color: #f36525;
}

.navbar-nav .mega-menu-item .sigma_btn-custom {
    display: inline-block;
    padding: 12px 30px;
    color: #fff;
    font-weight: 600;
}

.navbar-nav .mega-menu-item .sigma_btn-custom:hover {
    color: #fff;
    background-color: #e14702;
}

.navbar-nav .mega-menu-item a.coming-soon {
    color: #f5f5f5;
    cursor: default;
}

.navbar-nav .mega-menu-item a.coming-soon span {
    margin-left: 5px;
    font-size: 12px;
}

/*sigma_header-controls*/
.sigma_header-controls {
    display: flex;
    align-items: center;
}

.sigma_header-controls ul {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.sigma_header-controls ul>li {
    margin-top: 0;
}
.sigma_header-controls ul>li + li{
  margin-left: 15px;
}

.sigma_header-controls ul>li>a {
  position: relative;
  color: #fff;
  display: flex;
  align-items: center;
}
.sigma_header-controls ul>li.aside-toggler:hover,
.sigma_header-controls ul>li>a:hover{
  background-color: #f36525;
  border-color: #f36525;
  color: #fff;
}
.sigma_header-controls ul>li>a:hover i{
  color: #fff;
}
.sigma_header-controls ul>li.aside-toggler:hover span{
  background-color: #fff;
}

.sigma_notification-count {
  background: #f36525;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 600;
  border-radius: 50%;
  line-height: normal;
  position: absolute;
  top: -10px;
  right: -10px;
}

.sigma_header-contact{
  display: flex;
  align-items: center;
}
.sigma_header-contact i{
  font-size: 40px;
  line-height: 1;
  margin-right: 10px;
}
.sigma_header-contact span{
  font-size: 12px;
  color: #777;
  font-weight: 600;
}
.sigma_header-contact h6{
  margin: 0;
  line-height: 1;
}

/* Mobile sidenav */
.aside-toggler {
    display: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    cursor: pointer;
    transition: .3s;
}

.aside-toggler.desktop-toggler {
    display: flex;
    height: 58px;
    width: 58px;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    transition: .3s;
    border-radius: 50%;
    border: 1px solid #efefef;
}

.aside-toggler.desktop-toggler span {
    background-color: #313131;
}

.aside-toggler.desktop-toggler:hover {
    background-color: transparent;
}

.aside-toggler span {
    display: block;
    margin-bottom: 4px;
    width: 20px;
    height: 1px;
    border-radius: 0;
    background-color: #313131;
    transition: .3s;
    transform-origin: right;
}
.aside-toggler span:nth-child(even){
  width: 30px;
}

.sigma_header-controls.style-2 .aside-toggler.style-2,
.aside-toggler.style-2{
  display: none;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 4px;
}
.sigma_header-controls.style-2 .aside-toggler.style-2.desktop-toggler,
.aside-toggler.style-2.desktop-toggler{
  display: grid;
}

.aside-toggler.style-2 span{
  width: 8px;
  height: 8px;
  margin: 0;
}
.aside-toggler.style-2 span:nth-child(2){
  grid-row: 1;
  grid-column: 2;
}
.aside-toggler.style-2 span:nth-child(3){
  grid-row: 1;
  grid-column: 3;
}
.aside-toggler.style-2 span:nth-child(4){
  grid-row: 3;
  grid-column: 1;
}
.aside-toggler.style-2 span:nth-child(5){
  grid-row: 3;
  grid-column: 3;
}
.aside-toggler.style-2 span:nth-child(6){
  grid-row: 3;
  grid-column: 1;
}
.aside-toggler.style-2 span:nth-child(7){
  grid-row: 3;
  grid-column: 2;
}
.aside-toggler.style-2 span:nth-child(8){
  grid-row: 2;
  grid-column: 3;
}

.aside-toggler.style-2:hover span:nth-child(2),
.aside-toggler.style-2:hover span:nth-child(7),
.aside-toggler.style-2:hover span:nth-child(9),
.aside-toggler.style-2:hover span:nth-child(8){
  opacity: .4;
}


.aside-toggler.style-2:hover span{
  width: 8px;
  height: 8px;
  background-color: #f36525;
}

.sigma_aside {
  position: fixed;
  top: 0;
  left: -300px;
  width: 300px;
  height: 100%;
  background-color: #fff;
  z-index: 999;
  transition: .5s cubic-bezier(.77,0,.175,1);
  overflow: auto;
}

.sigma_aside-overlay.aside-trigger-left,
.sigma_aside.sigma_aside-left {
    display: none;
}

.sigma_aside.sigma_aside-right {
    left: auto;
    right: -400px;
    padding: 30px;
    width: 400px;
}

.sigma_aside.sigma_aside-bg::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .3;
    z-index: -1;
    background-position: 0 130%;
    background-repeat: no-repeat;

}

.sigma_aside.sigma_aside-right .sidebar {
    display: none;
}

.sigma_aside.open {
    left: 0;
}

.sigma_aside.sigma_aside-right.open {
    left: auto;
    right: 0;
}

.sigma_aside.open+.sigma_aside-overlay {
    opacity: 1;
    visibility: visible;
}

.sigma_aside-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 998;
    background-color: rgba(0, 0, 0, .7);
    transition: .3s;
    opacity: 0;
    visibility: hidden;
    cursor: pointer;
}

.sigma_aside ul {
    margin: 0;
}

.sigma_aside ul .menu-item {
    margin: 0;
}

.sigma_aside ul .menu-item a {
    padding: 10px 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: #313131;
    font-weight: 700;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.sigma_aside ul .menu-item a:hover,
.sigma_aside ul .menu-item a.active {
    color: #f36525;
}

.navbar-nav .menu-item-has-children>a::after,
.sigma_aside ul .menu-item.menu-item-has-children>a::after {
    font-family: "Font Awesome 5 Pro";
    content: "\f078";
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    font-weight: 900;
    text-rendering: auto;
    line-height: 1.7;
    margin-left: auto;
    font-size: 10px;
}
.navbar-nav .menu-item-has-children>a::after{
  margin-left: 10px;
}
.navbar-nav .sub-menu .menu-item-has-children>a::after{
  content: "\f054";
}

.sigma_aside ul .menu-item .sub-menu {
    display: none;
    background-color: #f8f8f8;
}

.sigma_aside .navbar-brand {
    padding: 10px 15px;
    display: block;
    width: 200px;
    margin: 0;
}

.sigma_aside.sigma_aside-right .sidebar-widget .widget-title {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 20px;
}

.sigma_aside.sigma_aside-right .sidebar-widget.widget-categories-icons+.sidebar-widget {
    margin-top: 0;
}
.sigma_aside.sigma_aside-right .sidebar-widget{
  padding: 0;
  border: 0;
  box-shadow: none;
  background-color: transparent;
}

/* -- Aside trigger Hover sequence -- */
.aside-toggler:hover span {
  width: 30px;
}

/* Header Cart */
.sigma_cart-sidebar-wrapper.sigma_aside{
  padding: 0;
  width: 60vw;
  max-width: 1200px;
  right: -1200px;
}
.sigma_cart-sidebar{
  display: flex;
  flex-direction: column;
  height: 100%;
}

.sigma_cart-sidebar-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 40px;
}
.sigma_cart-sidebar-body {
  position: relative;
  padding: 0 40px;
  height: calc(100% - 245px);
  overflow-y: auto;
}

.sigma_cart-sidebar-header .close-btn span {
  height: 30px;
  opacity: 1;
}
.sigma_cart-sidebar-header h4{
  margin: 0;
}
.sigma_cart-sidebar-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  background-color: #f9f9f9;
  padding: 40px;
  margin-top: auto;
}
.sigma_cart-sidebar-footer h4 {
  margin: 0;
  font-size: 20px;
}
.sigma_cart-sidebar-footer h4 span {
  font-size: 28px;
  margin-left: 10px;
}

.cart-open .sigma_cart-sidebar-wrapper.sigma_aside{
  right: 0;
}
.cart-open .sigma_aside-overlay.sigma_cart-sidebar-overlay{
  opacity: 1;
  visibility: visible;
}

.sigma_cart-sidebar-item{
  position: relative;
  padding: 40px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 2px dashed #9F9E9E;
}
.sigma_cart-sidebar-item:last-child{
  border-bottom: 0;
}
.sigma_cart-sidebar-item-body{
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 270px;
  display: -ms-flexbox;
  display: flex;
}
.sigma_cart-sidebar-item-body img{
  margin-right: 15px;
  width: 90px;
}
.sigma_cart-sidebar-item-body-inner{
  flex: 1;
}
.sigma_cart-sidebar-item-body-inner h5{
  margin-bottom: 10px;
  font-size: 18px;
}

/* 2.1. Header Style 2 */
.header-2 .sigma_header-middle .navbar{
  align-items: unset;
}

.header-2 .sigma_header-controls.style-2{
  padding: 0 30px;
  border-right: 1px solid #efefef;
}

.header-2 .sigma_header-controls.style-1 a{
  padding: 0 30px;
  border-left: 1px solid #efefef;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 30px;
  justify-content: center;
}

.header-2 .sigma_header-controls .sigma_btn-custom{
  margin-left: 30px;
}

.header-2 .sigma_header-middle .navbar-nav{
  margin-left: 50px;
  margin-right: auto;
}
.header-2 .sigma_header-middle .sigma_header-button{
  margin-right: 50px;
  margin-left: auto;
}

.header-2 .sigma_header-middle .navbar>.navbar-nav>.menu-item+.menu-item>a{
  border-left: 1px solid #efefef;
}
.header-2 .sigma_header-middle .navbar>.navbar-nav>.menu-item>a{
  padding-left: 30px;
  padding-right: 30px;
}

/* 2.2. Header Style 3 */

.header-absolute.sigma_header.header-3{
  padding: 15px;
}
.sigma_header.header-3 .sigma_header-middle{
  background-color: #fff;
}

.header-3 .sigma_header-middle .navbar{
  align-items: unset;
}

.header-3 .sigma_logo-wrapper{
  border-right: 1px solid #efefef;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-3 .sigma_logo-wrapper .navbar-brand{
  margin: 0;
}

.header-3 .sigma_header-controls{
  padding: 20px;
  border-left: 1px solid #efefef;
}

.sigma_header-inner{
  flex: 1;
}
.sigma_header-inner ul > li > a{
  color: #313131;
  padding: 20px 15px;
  font-size: 13px;
  position: relative;
  display: block;
  font-weight: 600;
}

.sigma_header-inner .navbar-nav,
.sigma_header-inner .sigma_header-top{
  padding: 0 20px;
}

/* 2.1. Header Style 4 */
.sigma_header.header-4.header-absolute{
  padding: 0;
}
.sigma_header.header-4.header-absolute .sigma_header-top{
  border-bottom: 0;
}

.sigma_header.header-4 .sigma_header-top-inner{
  align-items: center;
  justify-content: space-between;
}

.sigma_header.header-4 .sigma_header-middle .navbar{
  padding: 0 20px;
  background-color: #fff;
  box-shadow: 0px 10px 20px 0px rgba(53,82,99,0.09);
}

.sigma_header.header-4 .sigma_header-button{
  margin-left: auto;
  margin-right: 30px;
}

.sigma_header.header-4 .sigma_header-middle .navbar-nav{
  margin-left: 20px;
}
.sigma_header.header-4 .sigma_sm li a{
  color: #777;
}
.sigma_header.header-4 .sigma_sm li a:hover{
  color: #f36525;
}

.sigma_header.header-4 .sigma_header-top-links a{
  color: #313131;
  font-weight: 600;
}
.sigma_header.header-4 .sigma_header-top-links a:hover{
  color: #f36525;
}

/*======================
3. Footer
========================*/

.sigma_footer {
  background-color: #f7f7f7;
  padding: 80px 0;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
}

/* style 1 */
.sigma_footer .sigma_footer-links {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px 0;
}

.sigma_footer .sigma_footer-links li{
  position: relative;
  margin: 0;
}

.sigma_footer .sigma_footer-links li+li {
  margin-left: 20px;
}

.sigma_footer .sigma_footer-links li a {
  position: relative;
  color: #777;
  font-weight: 500;
}

.sigma_footer .sigma_footer-links li a:hover,
.sigma_footer .sigma_footer-copyright p a:hover {
  color: #f36525;
}

.sigma_footer .sigma_footer-copyright {
  margin-top: 25px;
}

.sigma_footer .sigma_footer-copyright p a {
  color: #313131;
  font-weight: 600;
}

.sigma_footer .sigma_footer-bottom {
  border-top: 1px solid #efefef;
  padding: 30px 0;
  margin-top: 30px;
  text-align: center;
}

.sigma_footer form span {
  opacity: 0.5;
  font-size: 14px;
  display: block;
  margin-top: 8px;
}

.sigma_footer .sigma_footer-widget .widget-title {
  margin-bottom: 20px;
}

.sigma_footer .sigma_footer-widget {
  padding-bottom: 50px;
  height: 100%;
}

.sigma_footer .sigma_footer-contact li,
.sigma-call{
  display: flex;
  align-items: center;
}

.sigma_footer .sigma_footer-contact li + li{
  margin-top: 20px;
}

.sigma_footer .sigma_footer-contact li i{
  color: #313131;
  font-size: 22px;
  margin-right: 20px;
}
.sigma_footer .sigma_footer-contact li p,
.sigma-call p{
  margin-bottom: 0;
}

.sigma-call i{
  color: #f36525;
  font-size: 32px;
  margin-right: 18px;
}
 .sigma-call h5{
  color: #313131;
  margin-bottom: 0;
  line-height: 1;
}

/* style 2 */
.sigma_footer .sigma_footer-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sigma_footer.style-2 .sigma_footer-links,
.sigma_footer.style-2 .sigma_footer-copyright {
  margin: 0;
}

.sigma_footer.style-2 select {
  width: 180px;
}

/* style 3 */
.sigma_footer.style-3 .sigma_footer-links,
.sigma_footer.style-5 .sigma_footer-links,
.sigma_footer.style-6 .sigma_footer-links,
.sigma_footer.style-7 .sigma_footer-links {
  flex-direction: column;
  align-items: start;
  margin: 0;
}

.sigma_footer.style-3 .sigma_footer-links li+li,
.sigma_footer.style-5 .sigma_footer-links li+li,
.sigma_footer.style-6 .sigma_footer-links li+li,
.sigma_footer.style-7 .sigma_footer-links li+li {
  margin-left: 0;
  margin-top: 14px;
}

/* style 4 */
.sigma_footer.style-4 .sigma_footer-top {
  border-bottom: 1px solid #efefef;
  padding-bottom: 50px;
  margin-bottom: 50px;
}

/* style 5 */

.sigma_footer.style-5 .sigma_footer-bottom{
  margin-top: 50px;
}

.sigma_footer.style-5.has-newsletter{
  padding-top: 160px;
}

.sigma_footer.style-5 .sigma_footer-bottom .sigma_footer-links,
.sigma_footer.style-6 .sigma_footer-bottom .sigma_footer-links{
  flex-direction: row;
}

.sigma_footer.style-5 .sigma_footer-bottom .sigma_footer-links li + li,
.sigma_footer.style-6 .sigma_footer-bottom .sigma_footer-links li + li{
  margin-left: 15px;
  padding-left: 15px;
  margin-top: 0;
}

.sigma_footer.style-5 .sigma_footer-bottom .sigma_footer-links li + li:before,
.sigma_footer.style-6 .sigma_footer-bottom .sigma_footer-links li + li:before{
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  height: 16px;
  background-color: #999b9f;
}

.sigma_footer.style-5 .sigma_info-wrapper.style-18{
  background-color: #1c1e22;
}

.sigma_footer.style-5 .sigma_footer-image{
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}

/* style 6 */

.sigma_footer.style-6,
.sigma_footer.style-7{
  padding-bottom: 0;
}

.sigma_footer.style-6:before,
.sigma_footer.style-7:before{
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(49, 49, 48, 0.9);
  z-index: -1;
}

.sigma_footer.style-6 .sigma_footer-bottom{
  background-color: #222429;
}

/* style 7 */

.sigma_footer.style-7.has-no-bg{
  background-image: none;
}

.sigma_footer.style-7.has-no-bg:before{
  content:  none;
}

.sigma_footer.style-7 .social-connect{
  margin-top: 20px;
}

.sigma_footer.style-7:before{
  background-color: rgba(49, 49, 48, 0.8);
}

.sigma_footer.style-7 .social-connect h6{
  color: #313131;
}

.sigma_footer.style-7 .social-connect ul li a{
  width: auto;
  height: auto;
  font-size: 18px;
  background-color: transparent;
}

.sigma_footer.style-7 .social-connect ul li a:hover{
  color: #e14702;
  background-color: transparent;
}

.sigma_footer.style-7 .social-connect .sigma_sm.square li+li {
    margin-left: 18px;
}

.sigma_footer.style-7 .sigma-call{
  background-color: #fff;
  padding: 16px 20px;
  display: block;
  width: 250px;
  position: relative;
}

.sigma_footer.style-7 .sigma-call p{
  color: #313131;
}

.sigma_footer.style-7 .sigma-call h5{
  color: #f36525;
}

.sigma_footer.style-7 .sigma-call i{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  color: #999b9f;
  opacity: 0.1;
  font-size: 60px;
}

.sigma_footer.style-7 form span{
  margin-top: 12px;
}


/* footer newsletter */

.sigma_footer-newsletter{
  position: relative;
  margin-bottom: -80px;
  z-index: 2;
}

.sigma_footer-newsletter:before{
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  background-color: #f36525;
}

.sigma_footer-newsletter .sigma_cta.style-9{
  box-shadow: none;
  padding-left: 0;
}

/* Dark Footer */
.sigma_footer.sigma_footer-dark {
  background-color: #222429;
}

.sigma_footer.sigma_footer-dark .sigma_sm.square li a {
  background-color: #1c1e22;
  color: #999b9f;
}

.sigma_footer.sigma_footer-dark .sigma_sm.square li a:hover {
  background-color: #f36525;
  color: #fff;
}

.sigma_footer.sigma_footer-dark,
.sigma_footer.sigma_footer-dark p,
.sigma_footer.sigma_footer-dark .sigma_footer-links li a,
.sigma_footer.sigma_footer-dark .sigma_footer-copyright p a,
.sigma_footer.sigma_footer-dark .widget.widget-sigma-recent-posts .sigma_recent-post .recent-post-descr h6 a,
.sigma_footer.sigma_footer-dark .widget.widget-sigma-recent-posts .sigma_recent-post .recent-post-descr .date {
  color: #999b9f;
}

.sigma_footer.sigma_footer-dark .sigma_footer-widget .widget-title,
.sigma_footer.sigma_footer-dark .sigma_footer-contact li i,
.sigma_footer.sigma_footer-dark .sigma-call h5,
.sigma_footer.sigma_footer-dark.style-7 .sigma-call p,
.sigma_footer.sigma_footer-dark.style-7 .sigma-call h5,
.sigma_footer.sigma_footer-dark .sigma_footer-links li a:hover,
.sigma_footer.sigma_footer-dark .sigma_footer-copyright p a:hover,
.sigma_footer.sigma_footer-dark.style-7 .social-connect ul li a:hover,
.sigma_footer.sigma_footer-dark.style-7 .social-connect h6,
.sigma_footer.sigma_footer-dark .widget.widget-sigma-recent-posts .sigma_recent-post .recent-post-descr h6 a:hover,
.sigma_footer.sigma_footer-dark .widget.widget-sigma-recent-posts .sigma_recent-post .recent-post-descr .date:hover {
  color: #fff;
}

.sigma_footer.sigma_footer-dark.style-4 .sigma_footer-top,
.sigma_footer.sigma_footer-dark .sigma_footer-bottom {
  border-color: rgba(255, 255, 255, 0.1);
}

.sigma_footer.sigma_footer-dark .form-control,
.sigma_footer.sigma_footer-dark select,
.sigma_footer.sigma_footer-dark input[type="email"],
.sigma_footer.sigma_footer-dark input[type="text"] {
  border-color: rgba(255, 255, 255, 0.1);
  background-color: #1c1e22;
}

.sigma_footer.sigma_footer-dark.style-7 .sigma-call{
  background-color: #f36525;
}

.sigma_instagram {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 300px;
    margin: 0 auto;
    text-align: center;
}

.sigma_ig-item {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0 2px 5px;
}
.sigma_ig-item::before{
  content: "\f16d";
  font-family: "Font Awesome 5 Brands";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  z-index: 3;
  transition: .3s;
  opacity: 0;
  visibility: hidden;
  color: #f36525;
  width: 40px;
  height: 40px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
}
.sigma_ig-item:hover::before{
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  visibility: visible;
}
.sigma_ig-item:hover img {
  transform: scale(1.1);
  filter: blur(1px);
}

.sigma_ig-item img {
  transition: .3s;
  width: 100%;
}



/*======================
5. Subheader
========================*/

.sigma_subheader {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.sigma_subheader-nav{
  background-color: #f36525;
}
.sigma_subheader-nav.sticky{
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 994;
}
.sigma_subheader-nav ul{
  margin: 0;
  display: flex;
  align-items: center;
}
.sigma_subheader-nav ul li{
  margin: 0;
}
.sigma_subheader-nav ul li a{
  padding: 20px;
  color: #fff;
  display: block;
  font-weight: 600;
}
.sigma_subheader-nav ul li a:hover{
  background-color: #e14702;
}

.sigma_subheader-inner {
    padding: 60px 0;
    text-align: center;
}
.header-absolute + .sigma_subheader .sigma_subheader-inner{
  padding: 270px 0 150px;
}
.header-absolute + .sigma_subheader.subheader-lg .sigma_subheader-inner{
  padding: 230px 0 340px;
}

.sigma_subheader h1 {
  font-size: 60px;
  margin-bottom: 20px;
  font-weight: 700;
  color: #fff;
}
.sigma_subheader p{
  color: #d2d2d2;
  max-width: 500px;
  margin: 20px auto 0;;
}

.sigma_subheader .sigma_subheader-text{
  margin: 0 auto;
  text-align: center;
}

.sigma_subheader .breadcrumb {
    padding: 15px 25px;
    display: inline-flex;
    margin: 0;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    border-radius: 35px;
}

.sigma_subheader .breadcrumb li {
    margin: 0;
    color: #f8f8f8;
}
.sigma_subheader .breadcrumb-item+.breadcrumb-item{
  padding-left: 15px;
}

.sigma_subheader .breadcrumb li a {
  position: relative;
  color: #777;
  font-weight: 700;
  text-decoration: none;
}
.sigma_subheader .breadcrumb li a::before{
  background-color: #fff;
}

.sigma_subheader .breadcrumb li a:hover {
    color: #777;
}

.sigma_subheader .breadcrumb .breadcrumb-item.active {
    color: #f36525;
    font-weight: 700;
}

.sigma_subheader .breadcrumb-item+.breadcrumb-item::before {
    color: #777;
    content: "|";
    margin-left: auto;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    padding-right: 15px;
}


.sigma_subheader .sigma_sm{
  justify-content: flex-end;
}
.sigma_subheader .sigma_sm li a{
  color: #777;
}
.sigma_subheader .sigma_sm li a:hover{
  color: #fff;
}

.vertical-toggler{
  display: flex;
  align-items: flex-end;
  cursor: pointer;
}
.vertical-toggler span{
  display: block;
  height: 20px;
  width: 3px;
  margin: 0 2px;
  transition: .3s;
  background-color: #f36525;
}
.vertical-toggler span:nth-child(odd){
  height: 30px;
}
.vertical-toggler:hover span{
  height: 30px;
}
.vertical-toggler:hover span:nth-child(odd){
  height: 20px;
}

.sigma_subheader-extras{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: rgba(255,255,255,.9);
  opacity: 0;
  visibility: hidden;
  padding: 30px;
  transition: .5s cubic-bezier(.77,0,.175,1);
  z-index: 49;
}
.sigma_subheader-extras .sigma_map{
  height: 300px;
}
.sigma_subheader-extras.open{
  height: 50%;
  opacity: 1;
  visibility: visible;
}
.sigma_subheader-extras .close-btn{
  position: absolute;
  top: 30px;
  right: 30px;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  border: 1px solid #272727;
}
.sigma_subheader-extras .close-btn span{
  height: 25px;
}

.sigma_subheader-extras .sigma_contact-info-item + .sigma_contact-info-item{
  margin-top: 20px;
  padding-top: 0;
  border-top: 0;
}

/*======================
6. Components
========================*/

/* 6.1. Blog Posts */
.sigma_post {
  position: relative;
  margin-bottom: 30px;
}

.sigma_post .sigma_post-thumb,
.gallery-thumb {
    position: relative;
    display: block;
}
.gallery-thumb{
  overflow: hidden;
  border-radius: 25px;
}
.sigma_post.sigma_post-list .sigma_post-thumb > a:not(.sigma_video-popup){
  display: block;
  overflow: hidden;
  position: relative;
}
.entry-content .gallery-thumb{
  margin-bottom: 20px;
}
.entry-content .gallery-thumb img{
  margin: 0;
}
.gallery-thumb img,
.sigma_post .sigma_post-thumb img{
  width: 100%;
  transition: .3s;
}
.gallery-thumb img{
  border-radius: 25px;
}
.gallery-thumb::before{
  z-index: 1;
}
.gallery-thumb span{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  z-index: 3;
}
.gallery-thumb span i{
  margin-right: 5px;
}

.gallery-thumb:hover img{
  transform: scale(1.2);
}


.gallery-thumb{
  z-index: 1;
}

.gallery-thumb .caption{
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: #fff;
  font-size: 11px;
  letter-spacing: 1.4px;
  z-index: 3;
}
.gallery-thumb.lg .caption{
  font-size: 18px;
}

.sigma_post .sigma_post-body {
    padding: 30px;
    box-shadow: 0 3px 24px rgba(0,0,0,.04);
    position: relative;
    background-color: #fff;
    z-index: 1;
}

.sigma_post-meta{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.sigma_post-meta i{
  margin-right: 5px;
  color: #f36525;
}

.sigma_post-meta a,
.sigma_post-date{
  font-size: 13px;
  color: #313131;
  font-weight: 600;
}
.sigma_post-meta a:hover{
  color: #f36525;
}

.sigma_post-categories{
  margin-right: 20px;
}

.sigma_post-categories a {
    background-color: #efefef;
    display: inline-block;
    padding: 4px 30px;
    color: #fff;
    font-weight: 500;
    font-size: 13px;
    line-height: 1.7;
    border-radius: 35px;
    margin: 0 3px 3px 0;
    background-color: #f36525;
}

.sigma_post-categories a:hover {
    background-color: #e14702;
    color: #fff;
}

.sigma_post-date span{
  color: #313131;
  transition: .3s;
  font-size: 18px;
  font-weight: 800;
}
.sigma_post-date:hover span{
  color: #e14702;
}
.sigma_post-date i{
  margin-right: 5px;
}

.sigma_post .sigma_post-body h5 {
    margin: 0 0 15px;
    word-break: break-word;
    font-size: 18px;
    margin-bottom: 0;
}
.sigma_post .sigma_post-body h5 + p{
  margin: 20px 0 0;
}

.sigma_post .sigma_post-body h5 a {
    color: #313131;
}

.sigma_post .sigma_post-body h5 a:hover,
.sigma_post .sigma_post-body .sigma_post-desc>span a:hover {
    color: #e14702;
}

.sigma_post .sigma_post-body .sigma_post-desc>span {
    display: block;
    color: #313131;
    line-height: 1.7;
    margin-bottom: 10px;
}

.sigma_post .sigma_post-body .sigma_post-desc>span a {
  color: #f36525;
}

.sigma_post .sigma_post-body .sigma_post-desc p {
  margin-bottom: 20px;
}

.sigma_post:not(.sigma_post-list) .sigma_post-footer{
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #efefef;
  display: flex;
  align-items: center;
}
.sigma_post:not(.sigma_post-list) .sigma_post-footer a{
  color: #777;
  font-size: 14px;
  line-height: 1;
}
.sigma_post:not(.sigma_post-list) .sigma_post-footer a + a{
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid #efefef;
}
.sigma_post:not(.sigma_post-list) .sigma_post-footer a:hover{
  color: #f36525;
}
.sigma_post:not(.sigma_post-list) .sigma_post-category{
  padding: 5px 20px;
  font-weight: 600;
  color: #fff;
  background-color: #f36525;
  position: absolute;
  bottom: -20px;
  font-size: 14px;
  right: 20px;
  z-index: 2;
  border-radius: 25px;
}
.sigma_post:not(.sigma_post-list) .sigma_post-category:hover{
  background-color: #e14702;
}
.sigma_post:not(.sigma_post-list) .sigma_post-body{
  padding: 40px 30px 30px;
}

.sigma_post.post-2 .sigma_post-category{
  position: relative;
  bottom: auto;
  right: auto;
  background-color: transparent;
  color: #f36525;
  padding: 0;
  display: block;
  margin-bottom: 10px;
}
.sigma_post.post-2 .sigma_post-category:hover{
  background-color: transparent;
  color: #e14702;
}

.sigma_post.post-2 .sigma_post-body{
  padding: 30px;
}
.sigma_post.post-2 .sigma_post-body .sigma_post-link{
  display: flex;
  align-items: center;
  margin-top: 20px;
  transition: .3s;
  font-size: 0;
}
.sigma_post.post-2 .sigma_post-body .sigma_post-link i{
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .3s;
  font-size: 14px;
  color: #313131;
  margin-right: 20px;
  box-shadow: 0 3px 24px rgba(0,0,0,.04);
}

.sigma_post.post-2 .sigma_post-body .sigma_post-link:hover i{
  background-color: #e14702;
  color: #fff;
}

/* 6.1.1. Blog Posts list */
.sigma_post.sigma_post-list .sigma_post-thumb{
  overflow: hidden;
}
.sigma_post.sigma_post-list .sigma_post-body{
  padding: 40px;
}
.sigma_post.sigma_post-list .sigma_post-single-author{
  margin-top: 0;
}

.sigma_post.sigma_post-list .sigma_post-body h5{
  font-size: 32px;
  margin-bottom: 15px;
}

.sigma_post.sigma_post-list .sigma_post-meta{
  margin-bottom: 15px;
}

.sigma_post.sigma_post-list .sigma_post-meta a + a{
  margin-left: 20px;
}
.sigma_post.sigma_post-list .sigma_post-footer{
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Formats */
.sigma_post .post-format-video.sigma_post-thumb .sigma_video-popup{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sigma_post .post-format-audio .embed-responsive-16by9::before{
  padding-top: 16.25%;
}
.sigma_post .post-format-quote{
  background-color: #f36525;
}

.sigma_post .post-format-quote h5 a,
.sigma_post .post-format-quote *{
  color: #fff;
}

.sigma_post .post-format-quote .sigma_post-meta a:hover,
.sigma_post .post-format-quote h5 a:hover,
.sigma_post .post-format-quote h5 a:hover{
  color: #313131;
}
.sigma_post .post-format-quote .sigma_post-body{
  padding: 40px;
  border: 0;
  background-color: transparent;
  display: flex;
  align-items: flex-start;
}
.sigma_post .post-format-quote .sigma_post-body > i{
  margin-right: 20px;
  font-size: 70px;
  line-height: 1;
}
.sigma_post .post-format-quote .sigma_post-meta{
  margin: 0;
}

.sigma_post .post-format-image .sigma_post-thumb{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.sigma_post .post-format-image .sigma_post-body{
  background-color: transparent;
}
.sigma_post .post-format-image .sigma_post-body p{
  margin: 0;
}
.sigma_post .post-format-image .sigma_post-body::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: rgba(255,255,255,.9);
}


/* Style 2 */
.sigma_post.style-2 .sigma_post-body{
  border: 0;
  border-top: 3px solid #f36525;
  box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
  width: calc( 100% - 40px );
  margin-left: 20px;
  margin-top: -45px;
}

/* 6.1.2. Blog Post Details */
.dropcap-wrapper{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.dropcap-wrapper .dropcap{
  margin-right: 15px;
  min-width: 60px;
  max-width: 60px;
  height: 60px;
  border-radius: 50%;
  font-weight: 600;
  font-size: 32px;
  background-color: #f36525;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dropcap-wrapper p{
  margin: 0;
}
.sigma_post-single .section{
  padding: 0;
}
.sigma_post-single .section + .section{
  padding-top: 50px;
}
.post-detail-wrapper{
  padding: 40px;
  border: 2px solid #efefef;
}

.post-detail-wrapper .sigma_post-meta a + a{
  margin-left: 20px;
}
.post-detail-wrapper .sigma_post-meta{
  margin-bottom: 20px;
}

.comments-list ul {
    margin: 0;
}
.comment-form{
  padding: 40px;
  background-color: #f8f8f8;
  border-radius: 35px;
}
.comments-list .comment-item {
  position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-bottom: 30px;
    margin-top: 0;
}
.comments-list .comment-item + .comment-item{
  border-top: 1px solid #efefef;
  padding-top: 30px;
}

.comments-list .comment-item:last-child {
    margin-bottom: 0;
}

.comments-list .comment-item img {
    width: 100px;
    margin-right: 20px;
    border-radius: 50%;
}

.comments-list .comment-item .comment-body {
    position: relative;
    flex: 1;
}

.comments-list .comment-item h5 {
    margin-bottom: 15px;
    font-size: 18px;
}

.comments-list .comment-item span {
    display: inline-block;
    font-size: 13px;
    margin-bottom: 15px;
    line-height: 21px;
    color: #f36525;
    font-weight: 600;
}

.comments-list .comment-item .btn-link{
  padding: 10px 25px;
  border: 2px solid #efefef;
  border-radius: 35px;
  font-size: 14px;
  position: absolute;
  top: 0;
  right: 0;
}

.comments-list .comment-item p {
    margin-bottom: 15px;
}

.reply-link {
    position: relative;
    color: #313131;
    font-weight: 600;
}

.sigma_post-single-thumb {
  position: relative;
    margin-bottom: 30px;
}
.sigma_post-single-thumb img{
  margin: 0;
}

.sigma_post-single-author {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.sigma_post-single-author img {
    width: 30px;
    border-radius: 50%;
    margin-right: 10px;
    margin-bottom: 0;
}
.sigma_post-single-author-content{
  display: flex;
  align-items: center;
}
.sigma_post-single-author-content p {
    font-weight: 600;
    color: #313131;
    margin-bottom: 0;
    margin-left: 5px;
}

.sigma_post-single-author-content span {
    font-size: 13px;
}

.sigma_author-about{
  display: flex;
  align-items: center;
  padding: 30px;
  border: 2px solid #efefef;
}
.sigma_author-about img{
  width: 150px;
  margin-right: 30px;
}
.sigma_author-about .sigma_author-about-content{
  flex: 1;
}
.sigma_author-about .sigma_author-about-content span{
  color: #f36525;
  font-weight: 600;
}
.sigma_author-about .sigma_author-about-content p{
  margin: 0;
}

.sigma_post-single-meta {
    display: flex;
    align-items: center;
    padding: 0 0 50px;
    margin-top: 50px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.sigma_post-single-meta-item {
    flex: 1;
}

.sigma_post-single-meta-item h6{
  margin-bottom: 5px;
}
.sigma_post-share .sigma_sm li{
  margin-top: 6px;
}
.sigma_post-share .sigma_sm li+li {
    margin-left: 30px;
}
.sigma_post-single-meta-item.sigma_post-share{
  text-align: right;
}
.sigma_post-single-meta-item.sigma_post-share .sigma_sm{
  justify-content: flex-end;
}

.sigma_post-single-meta-item .sigma_product-controls{
  position: relative;
  transform: translate(0,0);
  top: auto;
  left: auto;
  opacity: 1;
  visibility: visible;
  justify-content: flex-end;
}
.sigma_post-single-meta-item .sigma_product-controls a{
  background-color: #f8f8f8;
}
.sigma_product-single-content .sigma_post-single-meta-item.sigma_post-share{
  text-align: left;
  display: flex;
  align-items: center;
}
.sigma_product-single-content .sigma_post-single-meta-item.sigma_post-share h6{
  margin: 6px 20px 0 0;
}

.sigma_product-single-content .sigma_post-single-meta-item.sigma_post-share .sigma_sm{
  justify-content: flex-start;
}

.sigma_post-share .sigma_sm li a {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #d2d2d2;
}
.sigma_post-share .sigma_sm li a:hover{
  color: #f36525;
}

.sigma_single-pagination {
    margin: 30px 0;
    display: flex;
}


.sigma_single-pagination-thumb {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #efefef;
    background-color: #fff;
    border-radius: 0;
    margin-right: 10px;
}

.sigma_single-pagination a {
    display: flex;
    align-items: center;
}

.sigma_single-pagination a h6 {
    margin: 0;
    font-size: 14px;
    color: #fff;
}
.sigma_single-pagination span{
  color: #d2d2d2;
}

.sigma_single-pagination a img {
    max-width: 40px;
}
.sigma_single-pagination-prev{
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
.sigma_single-pagination-next{
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.sigma_single-pagination-prev,
.sigma_single-pagination-next {
    padding: 10px;
    background-color: #313131;
    transition: .3s;
    flex: 1;
}

.sigma_single-pagination-prev:hover,
.sigma_single-pagination-next:hover {
    background-color: #f36525;
}

.sigma_single-pagination-prev:hover i,
.sigma_single-pagination-next:hover i,
.sigma_single-pagination-prev:hover span,
.sigma_single-pagination-next:hover span{
  color: #fff;
}

.sigma_single-pagination i {
  color: #777;
  margin: 0 20px;
  transition: .3s;
}
.sigma_single-pagination-next a{
  justify-content: flex-end;
}

.sigma_single-pagination-prev:hover i {
    transform: translateX(-3px);
}

.sigma_single-pagination-next:hover i {
    transform: translateX(3px);
}

/* 6.2. Sidebar */
.sidebar-widget {
  padding: 30px;
  border: 2px solid #efefef;
  background-color: #fff;
}

.sidebar-widget + .sidebar-widget {
    margin-top: 30px;
}

.sidebar-widget .widget-title {
  position: relative;
  padding-left: 45px;
  margin-bottom: 30px;
}
.sidebar-widget .widget-title::before{
  content: '';
  width: 20px;
  height: 3px;
  border-radius: 4px;
  background-color: #f36525;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.sidebar-widget .widget-title::after{
  content: '';
  width: 5px;
  height: 3px;
  border-radius: 4px;
  background-color: #f36525;
  display: block;
  position: absolute;
  top: 50%;
  left: 25px;
  transform: translateY(-50%);
}

.sidebar-widget ul.sidebar-widget-list {
    margin: 0;
    padding: 0;
}

.sidebar-widget ul.sidebar-widget-list ul {
    padding-left: 20px;
}

.sidebar-widget ul.sidebar-widget-list li {
    margin: 0;
}

.sidebar-widget ul.sidebar-widget-list li ul li:first-child {
    margin-top: 20px;
}

.sidebar-widget ul.sidebar-widget-list li+li {
    margin-top: 20px;
}

.sidebar-widget ul.sidebar-widget-list li a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #777;
}

.sidebar-widget ul.sidebar-widget-list li a:hover {
    color: #f36525;
}

.widget-about-author-inner {
  text-align: center;
}
.widget-about-author-bg{
  height: 150px;
  background-size: cover;
  background-position: center;
  margin-bottom: 20px;
}

.widget-about-author-inner img {
    border-radius: 50%;
    width: 150px;
    margin: 0 auto 20px;
}

.widget-about-author-inner h5 {
    margin-bottom: 10px;
    font-size: 18px;
}
.widget-about-author-inner p{
  font-size: 14px;
}
.widget-about-author-inner .sigma_sm{
  justify-content: center;
}
.widget-about-author-inner .sigma_sm li a{
  color: #d2d2d2;
}
.widget-about-author-inner .sigma_sm li a:hover{
  color: #f36525;
}

.widget-recent-posts .sigma_recent-post{
  display: flex;
  align-items: center;
}

.widget-recent-posts .sigma_recent-post>a {
    position: relative;
    display: block;
    overflow: hidden;
    margin-right: 20px;
    width: 75px;
}

.widget-recent-posts .sigma_recent-post>a img {
    transition: .3s;
}

.widget-recent-posts .sigma_recent-post>a img:hover {
    transform: scale(1.1) rotate(2deg);
}

.sidebar-widget.widget-recent-posts .sigma_recent-post>a img{
  border-radius: 50%;
}
.sidebar-widget.widget-recent-posts .sigma_recent-post h6 a{
  color: #313131;
}
.sidebar-widget.widget-recent-posts .sigma_recent-post h6 a:hover{
  color: #f36525;
}

.widget-recent-posts .sigma_recent-post+.sigma_recent-post{
  padding-top: 20px;
  border-top: 1px solid #efefef;
}

.widget-recent-posts .sigma_recent-post-body{
  flex: 1;
}
.widget-recent-posts .sigma_recent-post-body > a{
  color: #707070;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 5px;
  display: block;
}
.widget-recent-posts .sigma_recent-post-body > a i{
  margin-right: 5px;
  color: #f36525;
}
.widget-recent-posts .sigma_recent-post-body > a:hover{
  color: #e14702;
}

.widget-recent-posts .sigma_recent-post h6 {
  margin-bottom: 6px;
  font-size: 16px;
  line-height: 1.2;
}

.widget-recent-posts .sigma_recent-post h6 a {
    color: #fff;
}

.widget-twitter p{
  font-size: 14px;
}
.widget-twitter strong{
  font-size: 13px;
}

.sigma_footer:not(.sigma_footer-dark) .widget-recent-posts .sigma_recent-post h6 a{
  color: #313131;
}
.sigma_footer:not(.sigma_footer-dark) .sigma_sm.square li a{
  background-color: #f8f8f8;
  color: #313131;
}
.sigma_footer:not(.sigma_footer-dark) .sigma_sm.square li a:hover{
  background-color: #f36525;
  color: #fff;
}

.sigma_footer:not(.sigma_footer-dark) .widget-recent-posts .sigma_recent-post h6 a:hover,
.widget-recent-posts .sigma_recent-post h6 a:hover,
.widget-recent-posts .sigma_recent-post span a:hover {
    color: #f36525;
}

.widget-recent-posts .sigma_recent-post span {
    font-size: 13px;
    line-height: 1.7;
    font-weight: 600;
    color: #313131;
}

.widget-recent-posts .sigma_recent-post span a {
    font-weight: 400;
    text-decoration: underline;
    color: #313131;
}

.widget-recent-posts .sigma_recent-post+.sigma_recent-post {
  margin-top: 20px;
}

.sidebar-widget.widget-logo img{
  width: 180px;
}

.sidebar-widget.widget-categories ul.sidebar-widget-list ul{
  padding: 0;
}

.sidebar-widget.widget-categories ul li a{
  padding: 15px;
  background-color: #f8f8f8;
  color: #777;
  font-size: 14px;
  font-weight: 600;
  border-radius: 35px;
}
.sidebar-widget.widget-categories ul li a.justify-content-start i{
  color: #f36525;
}
.sidebar-widget.widget-categories ul li a.justify-content-start:hover i{
  color: #fff;
}
.sidebar-widget.widget-categories ul li a span{
  border-radius: 50%;
  background-color: #f36525;
  color: #fff;
  font-size: 13px;
  width: 30px;
  transition: .3s;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-widget.widget-categories ul li a:hover{
  background-color: #f36525;
  color: #fff;
}

.sidebar-widget.widget-categories ul li a:hover span{
  background-color: #fff8e6;
  color: #f36525;
}

.tags a,
.tagcloud a,
.entry-content .wp-block-tag-cloud a {
  display: inline-block;
  font-size: 13px;
  padding: 3px 12px;
  color: #313131;
  font-weight: 500;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  background-color: #f8f8f8;
  margin: 6px 6px 0 0;
  font-weight: 600;
  border-radius: 35px;
}

.tags a:hover,
.tagcloud a:hover,
.entry-content .wp-block-tag-cloud a:hover {
    background-color: #f36525;
    color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.twitter-item + .twitter-item{
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #efefef;
}

.sigma_newsletter-form {
    position: relative;
}

.sigma_newsletter-form.primary-bg {
    background-color: #f36525;
}

.sigma_newsletter-form h5 {
    margin-bottom: 15px;
    position: relative;
    font-size: 24px;
}

.sigma_countdown-timer {
  text-align: center;
  background-color: #fff;
  border: 1px solid #efefef;
  padding: 10px;
  font-weight: 600;
  margin: 20px 0 0;
  transition: .3s;
}

/* Sttyle 2 */
.sidebar.sidebar-2 .sidebar-widget{
  border: 0;
  padding: 40px;
  background-color: #f8f8f8;
}
.sidebar.sidebar-2 .sidebar-widget.widget-categories ul li a{
  background-color: #fff;
}
.sidebar.sidebar-2 .sidebar-widget.widget-categories ul li a:hover{
  background-color: #f36525;
}
.sidebar.sidebar-2 .widget-title{
  padding: 0;
  font-size: 18px;
  margin-bottom: 20px;
}
.sidebar.sidebar-2 .widget-title::before,
.sidebar.sidebar-2 .widget-title::after{
  display: none;
}
.sidebar.sidebar-2 .sidebar-widget .sigma_search-adv-input .form-control{
  background-color: #fff;
}

/* 6.3. Products */
.sigma_product {
    position: relative;
    margin-bottom: 30px;
}

.sigma_product .sigma_product-thumb {
  position: relative;
    overflow: hidden;
}

.sigma_product .sigma_product-thumb.thumb-sm {
    max-width: 200px;
    margin: 0 auto 20px;
}

.sigma_product .sigma_product-thumb img {
    transition: .3s;
    width: 100%;
}

.sigma_product .sigma_product-thumb:hover img {
    transform: scale(1.1) rotate(3deg);
}
.sigma_product .sigma_product-body{
  position: relative;
  padding: 10px 0 0;
}
.sigma_product .sigma_product-body h5 {
    margin: 0 0 10px;
    font-size: 18px;
    font-weight: 400;
    word-break: break-word;
}

.sigma_product .sigma_product-body .sigma_product-title a {
    color: #313131;
}

.sigma_product .sigma_product-body .sigma_product-title a:hover {
    color: #e14702;
}

.sigma_product .sigma_badge-featured {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 3;
  background-color: #ffa80a;
  font-weight: 600;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.7;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.sigma_product .sigma_badge-featured i {
    display: inline-block;
}

.sigma_product .sigma_badge-sale {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 3;
  background-color: #313131;
  font-weight: 600;
  color: #fff;
  font-weight: 700;
  font-size: 11px;
  line-height: 1.7;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.sigma_product .sigma_rating-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.sigma_product .sigma_rating-wrapper span {
    font-weight: 600;
}

.sigma_product-price{
  display: flex;
  align-items: center;
}
.sigma_product-price span {
    display: block;
}

.sigma_product-price span:first-child {
    line-height: 1.2;
    color: #f36525;
    font-weight: 700;
}

.sigma_product-price span+span {
    color: #777;
    font-weight: 400;
    line-height: 1;
    margin-left: 10px;
    text-decoration: line-through;
    color: #d2d2d2;
}

.sigma_product-stock {
    font-weight: 600;
}

.sigma_product-stock.instock {
    color: #58da58;
}

.sigma_product-stock.outofstock {
    color: #f54978;
}

.sigma_product .sigma_rating {
    margin-bottom: 0;
}

.sigma_product-controls {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 20px;
    right: 20px;
    opacity: 0;
    transition: .3s;
    visibility: hidden;
}
.sigma_product:hover .sigma_product-controls{
    opacity: 1;
    visibility: visible;
}
.sigma_product-controls a {
    width: 40px;
    height: 40px;
    background-color: #fff;
    color: #777;
    border-radius: 50%;
}
.sigma_product-controls a + a{
  margin-top: 10px;
}

.sigma_product-controls a i {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.sigma_product-controls a:hover {
    background-color: #f36525;
    color: #fff;
    box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
}

.sigma_product-footer{
  display: flex;
  align-items: center;
}

.sigma_shop-global {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #efefef;
}

.sigma_shop-global p {
  color: #313131;
  font-weight: 600;
  margin-bottom: 0;
}

.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single,
.irs--flat .irs-bar,
.irs--flat .irs-handle>i:first-child {
    background-color: #f36525;
}

.irs--flat .irs-from:before,
.irs--flat .irs-to:before,
.irs--flat .irs-single:before {
    border-top-color: #f36525;
}

.irs--flat .irs-handle.state_hover>i:first-child,
.irs--flat .irs-handle:hover>i:first-child {
    background-color: #f36525;
}

/* 6.3.3 Products Quick View & Details */
.sigma_quick-view-modal .sigma_product-single-content {
    padding: 0 40px;
}

.sigma_quick-view-modal .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 3;
}

.sigma_product-single-content .sigma_rating-wrapper {
    margin-bottom: 0;
}

.sigma_product-single-content .sigma_product-excerpt{
    margin-bottom: 25px;
}
.sigma_product-variation-wrapper{
  display: flex;
  align-items: flex-start;
}
.sigma_product-variation-wrapper .form-group + .form-group{
  margin-left: 10px;
}

.sigma_product-single-content .sigma_product-price {
    margin-bottom: 20px;
    justify-content: flex-start;
}

.sigma_product-single-content .sigma_product-price span:first-child {
    font-size: 32px;
}

.sigma_product-single-content .sigma_product-price span {
    display: inline-block;
}

.sigma_product-single-content .sigma_product-price span+span {
    margin-left: 10px;
}

.sigma_product-single-content .sigma_post-single-meta{
  border: 0;
  padding: 0;
  margin: 20px 0 0;
}
.sigma_product-single-content .sigma_post-single-meta + h6{
  margin: 5px;
}

.sigma_product-buttons{
  display: flex;
  align-items: center;
}
.sigma_product-buttons a + a{
  margin-left: 10px;
}
.sigma_product-atc-form .qty-outter {
    display: flex;
    align-items: center;
}

.sigma_product-atc-form .qty-outter .sigma_btn-custom+.qty {
    margin-left: 20px;
}

.sigma_product-atc-form .qty-inner{
  margin-left: 10px;
}
.sigma_product-atc-form .qty-inner h6{
  margin: 0 10px 0 0;
  font-size: 13px
}

.qty {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    height: 60px;
    padding: 8px 15px;
    border-radius: 0;
    border: 0;
    border-radius: 0;
    width: 100%;
    color: #777;
    background-color: #fff;
    border: 1px solid #efefef;
    letter-spacing: 0.1px;
    border-radius: 55px;
}

.qty input {
    border: 0;
    outline: none;
    width: 30px;
    text-align: center;
    font-weight: 600;
    border: 0;
    margin: 0 5px;
}

.qty span.qty-subtract {
    border-right: 0;
    cursor: pointer;
}

.qty span.qty-add {
    border-left: 0;
    cursor: pointer;
}

.qty span:hover {
  background-color: #f36525;
  border-color: #f36525;
  color: #fff;
}

.qty span {
    font-size: 13px;
    transition: .3s;
    color: #313131;
    line-height: 1;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #efefef;
    border-radius: 50%;
}

.sigma_product-radio label{
  cursor: pointer;
  font-weight: 700;
  color: #313131;
  font-style: normal;
}

.sigma_product-radio label input{
  opacity: 0;
  visibility: hidden;
  position: absolute;
}
.sigma_product-radio label span{
  position: relative;
  z-index: 1;
  font-size: 13px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sigma_product-radio label span::before{
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: .3s;
  opacity: 0;
  visibility: hidden;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  z-index: -1;
  background-color: #f36525;
}
.sigma_product-radio label input:checked + span::before{
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  visibility: visible;
}
.sigma_product-radio label input:checked + span{
  color: #fff;
}

.sigma_product-additional-info .nav{
  border-bottom: 0;
  justify-content: center;
}

.sigma_product-additional-info .nav-link {
    margin: 0;
    border-radius: 0;
    font-weight: 600;
    color: #777;
    border-bottom: 2px solid transparent;
}

.sigma_product-additional-info .nav-item+.nav-item .nav-link {
    margin-left: 10px;

}

.sigma_product-additional-info .nav-item+.nav-item .nav-link:hover{
  color: #f36525;
}

.sigma_product-additional-info .tab-content {
    z-index: 1;
    margin-top: 0;
    padding: 40px;
    background-color: #fff;
    border-radius: 35px;
}


.sigma_product-additional-info .tab-content table {
    margin: 0;
}

.sigma_product-additional-info .sigma_rating-wrapper,
.sigma_product-additional-info .comment-body .sigma_rating {
    margin-bottom: 20px;
}
.sigma_product-additional-info .tab-content .comment-form,
.sigma_product-additional-info .tab-content .comments-list{
  padding: 0;
  border: 0;
  background-color: transparent;
  box-shadow: none;
}
.sigma_product-additional-info .tab-content .comments-list .comment-item{
  padding: 0;
  border: 0;
}
.sigma_product-additional-info .comment-form {
    margin-bottom: 50px;
}

.sigma_product-single-controls {
    margin-bottom: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sigma_product-single-controls .sigma_add-to-favorite {
    width: 50px;
    height: 50px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #efefef;
    margin: 0 10px 0 0;
}

.sigma_product-single-controls .sigma_add-to-favorite:hover {
    background-color: #f8f8f8;
}

.sigma_product-single-controls .sigma_add-to-favorite i {
    font-size: 21px;
    color: #777;
}

.sigma_product-meta {
    margin: 20px 0 0;
}

.sigma_product-single-2 .sigma_product-meta {
    margin: 20px 0 30px;
}

.sigma_product-meta li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 10px;
}

.sigma_product-meta li>span {
    font-weight: 600;
    color: #313131;
    width: 120px;
}

.sigma_product-meta li .sigma_product-meta-item span,
.sigma_product-meta li a {
    color: #777;
}

.sigma_product-meta li a:hover {
    color: #f36525;
}

.sigma_product-single-thumb,
.sigma_product-single-zoom>img {
    background-color: #fff;
}

.sigma_product-single-thumb .slider{
  margin-bottom: 30px;
}


/* 6.3.4. Cart & Wishlist */
td.remove {
    width: 30px;
}

.sigma_cart-product-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.sigma_cart-product-wrapper img {
    width: 50px;
    margin-right: 15px;
}

.sigma_cart-product-body {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.sigma_cart-product-wrapper h6 {
    margin-bottom: 0;
    font-size: 14px;
}

.sigma_cart-product-wrapper h6 a {
    color: #313131;
}

.sigma_cart-product-wrapper h6 a:hover {
    color: #f36525;
}

.sigma_cart-product-wrapper p {
    margin-bottom: 0;
    font-size: 13px;
    line-height: 21px;
    color: #848486;
}

/* 6.3.5. Checkout */
.sigma_notice {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 30px;
    border: 2px solid #efefef;
}

.sigma_notice p {
    margin: 0;
    font-weight: 600;
}

.sigma_notice p a:hover {
    text-decoration: underline;
}

.sigma_notice-content {
    margin-bottom: 30px;
    display: none;
}

/* 6.3.6 Products (Recent View) */
.sigma_product-recent {
    position: relative;
    background-color: #fff;
    box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
    border-radius: 8px;
    transition: .3s;
}

.sigma_product-recent:hover {
    background-color: #f8f8f8;
}

.sigma_product-recent .sigma_product-recent-thumb {
    display: flex;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px;
}

.sigma_product-recent+.sigma_product-recent {
    margin-top: 10px;
}

.sigma_product-recent img {
    width: 75px;
    margin-right: 10px;
}

.sigma_product-recent-content .sigma_product-price span {
    display: inline-block;
    font-weight: 400;
}

.sigma_product-recent-content .sigma_product-price span+span {
    margin-left: 5px;
}

.sigma_product-recent-content h6 {
    margin-bottom: 5px;
    font-weight: 600;
}

.sigma_product-recent button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 99;
    margin-left: auto;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid #efefef;
    outline: none;
    cursor: pointer;
    font-size: 18px;
    width: 40px;
    height: 40px;
}

/* 6.5. Banners */
.sigma_banner .bottom-skew{
  z-index: 2;
}
.sigma_banner .dark-overlay::before{
  background-color: rgba(49, 49, 49, .7);
}
.sigma_banner video{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.sigma_banner .slick-track,
.sigma_banner .slick-slide {
    margin: 0;
}

.sigma_banner {
  position: relative;
  padding: 0;
  background-color: #f8f8f8;
  border-radius: 8px;
}

.sigma_banner .section-title{
  max-width: 100%;
}
.sigma_banner .title{
  color: #313131;
  font-size: 80px;
  font-weight: 800;
}
.sigma_banner h4{
  font-weight: 400;
  margin: 0;
}

.sigma_banner .badge{
  margin-bottom: 10px;
}

.sigma_banner p:first-child {
    margin-bottom: 10px;
}

.sigma_banner .sigma_contact-info-item p{
  color: #777;
}

.sigma_banner p {
    margin-bottom: 25px;
    color: #d2d2d2;
    max-width: 600px;
}

.sigma_banner .slick-dots {
    padding: 0 0 10px;
}

.sigma_banner .slick-dots li button {
    background-color: #fff;
}

.sigma_banner .slick-dots li.slick-active button {
    background-color: #f36525;
}

.sigma_banner .sigma_banner-slider-inner .sigma_banner-text {
    position: relative;
    z-index: 1;
    width: 100%;
}
.sigma_banner .sigma_banner-slider-inner .sigma_banner-text .text-center p{
  margin: 0 auto 25px;
}

.banner-1.sigma_banner .sigma_banner-slider-inner {
    overflow: hidden;
    position: relative;
    padding: 210px 0 120px;
}

/* Style 2 */
.banner-2.sigma_banner .sigma_banner-slider-inner {
  overflow: hidden;
  position: relative;
  padding: 300px 0 330px;
}

.banner-2.sigma_banner .title{
  font-size: 80px;
  line-height: 1;
}
.banner-2.sigma_banner p{
  font-size: 18px;
}

.sigma_banner-logo{
  position: absolute;
  top: 50%;
  left: -150px;
  transform: translateY(-50%);
  background-color: #fff;
  width: 700px;
  height: 700px;
  border-radius: 50%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-left: 100px;
}
.sigma_banner-logo::before{
  content: '';
  position: absolute;
  top: -40px;
  left: -40px;
  width: calc(100% + 80px);
  height: calc(100% + 80px);
  display: block;
  border: 5px solid rgba(255,255,255,.4);
  border-radius: 50%;
}
.sigma_banner-logo .sigma_sm.square{
  margin-top: 50px;
}
.sigma_banner-logo .sigma_sm.square li a{
  background-color: #f8f8f8;
  color: #313131;
  border-radius: 50%;
}
.sigma_banner-logo .sigma_sm.square li a:hover{
  background-color: #313131;
  color: #fff;
}

/* Style 3 */
.banner-3.sigma_banner .slick-track {
  display: flex;
}

.banner-3.sigma_banner .slick-slide {
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
}

.banner-3.sigma_banner .slick-track,
.banner-3.sigma_banner {
    padding: 0;
}

.banner-3.sigma_banner .sigma_banner-slider-inner {
    overflow: hidden;
    position: relative;
    padding: 240px 0 280px;
}

.header-absolute + .banner-3.sigma_banner .sigma_banner-slider-inner{
  padding: 300px 0 270px;
}

.banner-3.sigma_banner .slick-slider:hover .slick-prev {
    left: 70px;
}

.banner-3.sigma_banner .slick-slider:hover .slick-next {
    right: 70px;
}

.banner-3 > .container-fluid{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.banner-3 > .container-fluid .sigma_sm{
  justify-content: flex-end;
}
.banner-3 > .container-fluid .sigma_sm li a{
  color: #777;
}
.banner-3 > .container-fluid .sigma_sm li a:hover{
  color: #fff;
}

.banner-3 .sigma_arrows .prev-arrow{
  position: absolute;
  top: 50%;
  left: 40px;
  transform: translateY(-50%);
  z-index: 2;
}
.banner-3 .sigma_arrows .next-arrow{
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
  z-index: 2;
}
.banner-3 .sigma_arrows .sigma_round-arrow svg{
  width: 90px;
  height: 90px;
}
.banner-3 .sigma_arrows .sigma_round-arrow .sigma_round-arrow-circle{
  stroke: #f36525;
}

/* 6.6. Icon Blocks */
.sigma_block-box,
.sigma_icon-block {
    padding: 20px;
    position: relative;
    margin-bottom: 30px;
    background-color: #fff;
    border-radius: 0;
    z-index: 1;
    box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
    transition: .3s;
}

.sigma_icon-block i {
    font-size: 40px;
    line-height: 1;
    margin: 0 0 15px;
    color: #777;
    display: inline-block;
}

.sigma_icon-block.has-link:hover {
    background-color: #f8f8f8;
}

.sigma_icon-block a {
    display: block;
}


.sigma_icon-block h5 {
    font-size: 22px;
    margin-bottom: 0;
}

.sigma_icon-block h5+p {
    margin-top: 10px;
}

.sigma_icon-block p {
    margin-bottom: 0;
}

.sigma_icon-block svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.sigma_icon-block.icon-block-1{
  display: flex;
  align-items: center;
  border-radius: 25px;
}
.sigma_icon-block.icon-block-1 i{
  margin: 0 15px 0 0;
}
.sigma_icon-block.icon-block-1 h5{
  font-size: 16px;
}
.sigma_icon-block.icon-block-1 h5+p{
  margin-top: 5px;
}

/* Style 2 */
.sigma_icon-block.icon-block-2{
  box-shadow: none;
  border-radius: 25px;
  border: 1px solid #efefef;
  text-align: left;
  padding: 30px;
}
.sigma_icon-block.icon-block-2 *{
  transition: .3s;
}
.sigma_icon-block.icon-block-2 .icon-wrapper-outer{
  display: flex;
  align-items: center;
}

.sigma_icon-block.icon-block-2 .icon-wrapper-outer i{
  font-size: 24px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #f36525;
  box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
  border-radius: 50%;
  margin-right: 20px;
}

.sigma_icon-block.icon-block-2 .sigma_icon-block-content{
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sigma_icon-block.icon-block-2 .sigma_icon-block-content i{
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #313131;
  color: #fff;
  font-size: 20px;
  border-radius: 50%;
  margin: 0;
}

.sigma_icon-block.icon-block-2:hover{
  background-color: #313131;
  box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
}
.sigma_icon-block.icon-block-2:hover *{
  color: #fff;
}

.sigma_icon-block.icon-block-2:hover .icon-wrapper-outer i,
.sigma_icon-block.icon-block-2:hover .sigma_icon-block-content i{
  background-color: #f36525;
  color: #fff;
}

/* Style 3 */
.sigma_icon-block.icon-block-3{
  background-color: #313131;
  text-align: center;
  padding: 40px;
  overflow: hidden;
  border-radius: 25px;
}
.sigma_icon-block.icon-block-3::before{
  content: '';
  position: absolute;
  top: -40px;
  right: -40px;
  border-radius: 50%;
  background-color: rgba(255,255,255,.1);
  width: 120px;
  height: 120px;
  transition: .3s;
}
.sigma_icon-block.icon-block-3 .icon-wrapper i{
  width: 80px;
  height: 80px;
  background-color: #fff;
  border-radius: 50%;
  color: #313131;
  margin: 0 auto 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sigma_icon-block.icon-block-3 p{
  color: #d2d2d2;
}
.sigma_icon-block.icon-block-3 h5{
  color: #fff;
}
.sigma_icon-block.icon-block-3 .sigma_icon-block-content{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  font-weight: 600;
  font-size: 0;
}
.sigma_icon-block.icon-block-3 .sigma_icon-block-content i{
  width: 50px;
  height: 50px;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin: 0;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #d2d2d2;
  transition: .3s;
}
.sigma_icon-block.icon-block-3 .sigma_icon-block-content span{
  margin-left: 10px;
}
.sigma_icon-block.icon-block-3:hover{
  transform: translateY(-5px);
}
.sigma_icon-block.icon-block-3:hover .sigma_icon-block-content i{
  border-color: #fff;
  color: #f36525;
  transform: rotate(45deg);
}
.sigma_icon-block.icon-block-3:hover::before{
  width: 160px;
  height: 160px;
}

/* Style 4 */
.sigma_icon-block.icon-block-4{
  border-radius: 25px;
  padding: 40px 30px;
}
.sigma_icon-block.icon-block-4 .number{
  font-size: 100px;
  line-height: 1;
  font-weight: 700;
  position: absolute;
  top: 0;
  right: 0;
  transition: .3s;
  opacity: 0;
  visibility: hidden;
}
.sigma_icon-block.icon-block-4:hover .number{
  opacity: .1;
  visibility: visible;
}
.sigma_icon-block.icon-block-4:hover{
  background-color: #313131;
}

.sigma_icon-block.icon-block-4:hover p{
  color: #d2d2d2;
}
.sigma_icon-block.icon-block-4:hover i{
  color: #f36525;
}
.sigma_icon-block.icon-block-4:hover h5{
  color: #fff;
}

/* 6.7. Call to Actions */

.sigma_cta-sec.style-2{
  padding-top: 62px;
}

.sigma_cta{
  padding: 30px;
  border-radius: 25px;
}
.sigma_cta::before{
  border-radius: 25px;
  background-color: rgba(49, 49, 49, .7);
}
.sigma_cta h5{
  color: #fff;
  margin: 5px;
}
.sigma_cta p{
  color: #d2d2d2;
  margin: 0;
}

.sigma_cta.style-2{
  border-radius: 35px;
  background-color: #fff;
  box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
}

.sigma_cta.style-2 h5{
  color: #313131;
  margin: 0;
  font-size: 18px;
  padding-left: 170px;
}
.sigma_cta.style-2 img{
  position: absolute;
  bottom: 0;
  left: 40px;
}

.sigma_box.cta_form{
  padding: 50px;
  margin: 0 0 30px;
}

/* 6.8. Login & Signup */
.sigma_auth-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-shadow: 0 3px 12px -4px rgba(0, 0, 0, .1);
    box-shadow: 0 3px 12px -4px rgba(0, 0, 0, .1);
}

.sigma_auth-description {
    width: 600px;
    padding: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.sigma_auth-description-inner {
    text-align: center;
    max-width: 400px;
}

.sigma_auth-description-inner h2 {
    margin: 0;
    color: #fff;
}

.sigma_auth-description-inner p {
    margin: 30px 0;
    color: #fff;
}

.sigma_auth-description-inner i {
    color: #fff;
    line-height: 1;
    font-size: 40px;
    margin-bottom: 30px;
    display: block;
}

.sigma_social-login {
    margin-bottom: 20px;
}

.sigma_social-login+p a {
    display: inline-block;
}

.sigma_auth-form {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    background-color: #f8f8f8;
    padding: 60px;
    text-align: center;
}

.sigma_auth-form h2 {
    margin-bottom: 50px;
}

.sigma_auth-form form {
    max-width: 400px;
    margin: 0 auto;
}

.sigma_auth-form .sigma_btn-custom {
    margin-top: 20px;
}

.sigma_auth-form a {
    display: block;
    color: #313131;
    text-decoration: underline;
}

.sigma_auth-seperator {
    margin: 30px 0;
    text-align: center;
}

.sigma_auth-seperator span {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #9F9E9E;
    font-size: 13px;
    font-weight: 500;
}

.sigma_auth-seperator span::before {
    content: '';
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    background-color: rgba(0, 0, 0, .1);
    height: 1px;
    margin-right: 20px;
}

.sigma_auth-seperator span::after {
    content: '';
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    background-color: rgba(0, 0, 0, .1);
    height: 1px;
    margin-left: 20px;
}

.sigma_social-login-btn {
    cursor: pointer;
    display: block;
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
    white-space: nowrap;
    line-height: 24px;
    border: 0;
    padding: 20px 20px;
    border-radius: 3px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.sigma_social-login-btn i {
    margin-right: 5px;
}

.sigma_auth-mini {
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 8px;
}

.sigma_auth-mini h5 {
    margin-bottom: 15px;
}

.sigma_auth-mini a {
    display: block;
    color: #313131;
    text-decoration: underline;
}

.sigma_auth-mini a:hover {
    color: #f36525;
}

/* 6.9. Error 404 */
.section.error{
  padding: 120px 0 480px;
}
.section.error h1{
  font-size: 130px;
  line-height: 1;
  margin: 0 0 40px;
}

/* History */
.sigma_history .nav{
  position: relative;
  justify-content: space-around;
  margin-top: 80px;
  margin-bottom: 60px;
}
.sigma_history .nav::before{
  content: '';
  background-color: #5a5a5a;
  height: 1px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.sigma_history .nav-tabs .nav-item + .nav-item,
.sigma_history .nav-tabs .nav-item{
  margin: -30px 0 0;
}

.sigma_history .nav-tabs .nav-item .nav-link{
  background-color: transparent;
  padding: 0;
  color: #d2d2d2;
  border: 0;
  position: relative;
}
.sigma_history .nav-tabs .nav-item .nav-link.active{
  color: #f36525;
}
.sigma_history .nav-tabs .nav-item .nav-link::before{
  content: '';
  position: absolute;
  top: calc(50% + 19px);
  left: 50%;
  width: 1px;
  height: 10px;
  transform: translate(-50%, -50%);
  background-color: #f36525;
}


/* 6.12 Team Members */
.sigma_team{
  position: relative;
  background-color: #fff;
  box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
  margin-bottom: 30px;
  border-bottom: 4px solid #f36525;
}
.sigma_team-thumb{
  overflow: hidden;
}
.sigma_team-thumb img{
  width: 100%;
  transition: .3s;
}
.sigma_team-thumb:hover img{
  transform: scale(1.1);
}
.sigma_team-body{
  text-align: center;
  padding: 20px;
}

.sigma_team-info h5{
  margin-bottom: 5px;
}
.sigma_team-info p{
  font-size: 15px;
  line-height: 24px;
  color: #f36525;
  padding: 0;
}
.sigma_team-info{
  margin: 0 0 15px;
}
.sigma_team-sm .sigma_sm{
  justify-content: center;
}
.sigma_team-sm .sigma_sm li a{
  display: flex;
  font-size: 12px;
}
.sigma_team-sm .sigma_sm li + li{
  margin-left: 15px;
}

/* Style 2 */
.sigma_team.team-2{
  background-color: transparent;
  border-bottom: 0;
  z-index: 1;
}
.sigma_team.team-2 .sigma_team-body{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  text-align: left;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
  transition: .3s;
}

.sigma_team.team-2 .sigma_team-body .sigma_team-info{
  margin: 0;
}
.sigma_team.team-2 .sigma_team-body .sigma_team-info h5{
  margin: 0;
  font-size: 18px;
}
.sigma_team.team-2 .sigma_team-body .sigma_team-info p{
  margin: 0 0 10px;
}

.sigma_team.team-2 .sigma_team-body .sigma_team-sm .sigma_sm{
  position: absolute;
  top: -10px;
  left: -10px;
  display: flex;
  flex-direction: column;
}
.sigma_team.team-2 .sigma_team-body .sigma_team-sm li a{
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  background-color: #f36525;
  justify-content: center;
}
.sigma_team.team-2 .sigma_team-body .sigma_team-sm li a:hover{
  background-color: #e14702;
}
.sigma_team.team-2 .sigma_team-body .sigma_team-sm li + li{
  margin: 0;
}

.sigma_team.team-2:hover::before,
.sigma_team.team-2:hover .sigma_team-body{
  opacity: 1;
  visibility: visible;
}
.sigma_team.team-2::before{
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba( 0,0,0, 0.6);
  visibility: hidden;
  opacity: 0;
  transition: .3s;
}

.sigma_team.team-2 .sigma_team-sm .sigma_sm li a,
.sigma_team.team-2 .sigma_team-info p,
.sigma_team.team-2 .sigma_team-info h5{
  color: #fff;
}

/* Style 3 */
.sigma_team.team-3{
  padding: 20px;
  border-bottom: 0;
  text-align: center;
}
.sigma_team.team-3 .sigma_team-body{
  padding: 0;
}
.sigma_team.team-3 .sigma_team-thumb{
  margin-bottom: 20px;
  text-align: center;
  display: inline-block;
  position: relative;
}
.sigma_team.team-3 .sigma_team-thumb::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 20px );
  height: calc(100% - 20px );
  margin: 10px;
  border-radius: 50%;
  background-color: #313131;
  opacity: 0;
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  transition: .3s;
  transform: scale(0);
}
.sigma_team.team-3 .sigma_team-thumb::after{
  content: "\f067";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 4;
  opacity: 0;
  visibility: hidden;
  color: #fff;
  font-size: 20px;
  transition: .3s;
  transform: translate(-50%, -50%) scale(0);
}
.sigma_team.team-3:hover .sigma_team-thumb::before{
  transform: scale(1);
  opacity: .6;
  visibility: visible;
}
.sigma_team.team-3:hover .sigma_team-thumb::after{
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  visibility: visible;
}
.sigma_team.team-3 .sigma_team-thumb:hover img{
  transform: scale(1);
}
.sigma_team.team-3 .sigma_team-thumb img{
  border-radius: 50%;
  width: 120px;
  margin: 0 auto;
}
.sigma_team.team-3 .sigma_team-info img{
  width: 30px;
  margin-bottom: 20px;
}
.sigma_team.team-3 .sigma_team-info i{
  font-size: 24px;
  margin-bottom: 20px;
  display: inline-block;
  line-height: 1;
}

/* Style 4 & 5 */
.sigma_team.team-5,
.sigma_team.team-4{
  background-color: transparent;
  box-shadow: none;
  border-bottom: 0;
}
.sigma_team.team-5 .sigma_team-thumb,
.sigma_team.team-4 .sigma_team-thumb{
  position: relative;
  width: 200px;
  margin: 0 auto 20px;
  border-radius: 50%;
}
.sigma_team.team-5 .sigma_team-info p,
.sigma_team.team-4 .sigma_team-info p{
  margin: 0 0 5px;
  font-weight: 600;
}

.sigma_team.team-5 .sigma_team-info h5,
.sigma_team.team-4 .sigma_team-info h5{
  margin: 0;
}

.sigma_team.team-5 .sigma_team-thumb,
.sigma_team.team-4 .sigma_team-thumb{
  border-radius: 50%;
  overflow: visible;
}

.sigma_team.team-5 .sigma_team-thumb img,
.sigma_team.team-4 .sigma_team-thumb img{
  border-radius: 50%;
}

.sigma_team.team-5 .sigma_team-thumb:hover img,
.sigma_team.team-4 .sigma_team-thumb:hover img{
  transform: scale(1);
}

.sigma_team.team-5 .sigma_team-thumb .sigma_sm li,
.sigma_team.team-4 .sigma_team-thumb .sigma_sm li{
  position: absolute;
  top: 0;
  right: 25px;
}

.sigma_team.team-5 .sigma_team-thumb .sigma_sm a,
.sigma_team.team-4 .sigma_team-thumb .sigma_sm a{
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #313131;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition-duration: .3s;
}

.sigma_team.team-5 .sigma_team-body,
.sigma_team.team-4 .sigma_team-body{
  padding: 0;
}

.sigma_team.team-5 .sigma_team-thumb .sigma_sm a.trigger-team-socials,
.sigma_team.team-4 .sigma_team-thumb .sigma_sm a.trigger-team-socials{
  border: 2px solid #fff;
  opacity: 1;
  visibility: visible;
}

.sigma_team.team-5 .sigma_team-thumb .sigma_sm a.trigger-team-socials i,
.sigma_team.team-4 .sigma_team-thumb .sigma_sm a.trigger-team-socials i{
  transition: .3s;
}

.sigma_team.team-5 .sigma_team-thumb .sigma_sm.visible a.trigger-team-socials i,
.sigma_team.team-5 .sigma_team-thumb .sigma_sm a.trigger-team-socials:hover i,
.sigma_team.team-4 .sigma_team-thumb .sigma_sm.visible a.trigger-team-socials i,
.sigma_team.team-4 .sigma_team-thumb .sigma_sm a.trigger-team-socials:hover i{
  transform: rotate(45deg);
}

.sigma_team.team-5 .sigma_team-thumb .sigma_sm.visible a,
.sigma_team.team-4 .sigma_team-thumb .sigma_sm.visible a{
  opacity: 1;
  visibility: visible;
}

.sigma_team.team-5 .sigma_team-thumb .sigma_sm.visible a.trigger-team-socials,
.sigma_team.team-5 .sigma_team-thumb .sigma_sm a:hover,
.sigma_team.team-4 .sigma_team-thumb .sigma_sm.visible a.trigger-team-socials,
.sigma_team.team-4 .sigma_team-thumb .sigma_sm a:hover{
  background-color: #f36525;
}

.sigma_team.team-5 .sigma_team-thumb .sigma_sm li:hover,
.sigma_team.team-4 .sigma_team-thumb .sigma_sm li:hover{
  opacity: 1;
  visibility: visible;
}

.sigma_team.team-5 .sigma_team-thumb .sigma_sm li:nth-child(2),
.sigma_team.team-4 .sigma_team-thumb .sigma_sm li:nth-child(2){
  top: 45px;
  right: -10px;
}

.sigma_team.team-5 .sigma_team-thumb .sigma_sm li:nth-child(3),
.sigma_team.team-4 .sigma_team-thumb .sigma_sm li:nth-child(3){
  top: 100px;
  right: -15px;
}

.sigma_team.team-5 .sigma_team-thumb .sigma_sm li:nth-child(4),
.sigma_team.team-4 .sigma_team-thumb .sigma_sm li:nth-child(4){
  top: 150px;
  right: 15px;
}

.sigma_team.team-5 .sigma_team-thumb .sigma_sm li:nth-child(2) a,
.sigma_team.team-4 .sigma_team-thumb .sigma_sm li:nth-child(2) a{
  transition-delay: .1s;
}

.sigma_team.team-5 .sigma_team-thumb .sigma_sm li:nth-child(3) a,
.sigma_team.team-4 .sigma_team-thumb .sigma_sm li:nth-child(3) a{
  transition-delay: .2s;
}

.sigma_team.team-5 .sigma_team-thumb .sigma_sm li:nth-child(4) a,
.sigma_team.team-4 .sigma_team-thumb .sigma_sm li:nth-child(4) a{
  transition-delay: .3s;
}

.sigma_team.team-5 .sigma_team-thumb img{
  border-radius: 0;
}

.sigma_team.team-5 .sigma_team-thumb .sigma_sm{
  position: absolute;
  bottom: calc(100% - 40px);
  right: 0;
  flex-direction: column;
}

.sigma_team.team-5 .sigma_team-thumb .sigma_sm li:nth-child(4),
.sigma_team.team-5 .sigma_team-thumb .sigma_sm li:nth-child(3),
.sigma_team.team-5 .sigma_team-thumb .sigma_sm li:nth-child(2),
.sigma_team.team-5 .sigma_team-thumb .sigma_sm li{
  position: relative;
  top: auto;
  right: auto;
}
.sigma_team.team-5 .sigma_team-thumb .sigma_sm li + li{
  margin: 0;
}
.sigma_team.team-5 .sigma_team-thumb .sigma_sm li a{
  border-radius: 0;
  background-color: #fff;
  color: #313131;
}
.sigma_team.team-5 .sigma_team-thumb .sigma_sm li a:hover{
  color: #fff;
}
.sigma_team.team-5 .sigma_team-thumb .sigma_sm a.trigger-team-socials{
  border: 0;
  background-color: #f36525;
  color: #fff;
}
.sigma_team.team-5 .sigma_team-thumb .sigma_sm.visible a.trigger-team-socials,
.sigma_team.team-5 .sigma_team-thumb .sigma_sm a.trigger-team-socials:hover{
  background-color: #313131;
}
.sigma_team.team-5 .sigma_team-thumb{
  width: auto;
  margin: 0;
}
.sigma_team.team-5 .sigma_team-info{
  margin: 0;
  position: absolute;
  bottom: 0;
  padding: 20px 40px 20px 30px;
  background-color: #fff;
  width: calc(100% - 40px);
}
.sigma_team.team-5 .sigma_team-body{
  padding: 30px;
  background-color: #fff;
  text-align: left;
  border-top: 0;
  transition: .3s;
}
.sigma_team.team-5 .sigma_team-info h5{
  font-size: 20px;
}
.sigma_team.team-5 .sigma_team-info p{
  color: #777;
  margin: 0;
}
.sigma_team.team-5{
  transition: .3s;
}
.sigma_team.team-5:hover{
  box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
}
.sigma_team.team-5:hover .sigma_team-body{
  border-color: transparent;
}

/* 6.13. Pricing */
.sigma_pricing{
  background-image: url(../img/pricing.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 26px 20px 44px 20px;
  margin-bottom: 30px;
}
.sigma_pricing .sigma_pricing-icon{
  margin-bottom: 18px;
}
.sigma_pricing-title{
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 25px;
}
.sigma_pricing-price{
  position: relative;
  display: inline-block;
  line-height: 1;
}
.sigma_pricing-price .sigma_pricing-currency{
  position: absolute;
  top: 0;
  left: -12px;
  font-size: 20px;
  font-weight: 400;
}
.sigma_pricing-price span{
  font-size: 48px;
  font-weight: 800;
  color: #f36525;
}
.sigma_pricing .list-style-none{
  margin-top: 20px;
}
.sigma_pricing .sigma_btn-custom{
  margin-top: 35px;
}

.sigma_pricing .list-style-none li i{
  margin-right: 10px;
}

/* Style 2 */
.sigma_pricing.pricing-2{
  background-image: none;
  background-color: #fff;
  padding: 30px;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
}
.sigma_pricing.pricing-2::before{
  content: '';
  position: absolute;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  right: -100px;
  top: -90px;
  background-color: #fff8e6;
  border-radius: 50%;
}
.sigma_pricing.pricing-2 > i{
  position: absolute;
  top: 10px;
  right: 40px;
  font-size: 60px;
  color: #f36525;
}

.sigma_pricing.pricing-2.main > i{
  color: #fff;
}
.sigma_pricing.pricing-2.main::before{
  background-color: #f36525;
}

.sigma_pricing.pricing-2 .sigma_pricing-price{
  margin-left: 10px;
}

.sigma_pricing.pricing-2 .list-style-none{
  margin: 40px 0 0;
}

/* Style 3 */
.sigma_pricing.pricing-3{
  background-image: none;
  background-color: #fff;
  padding: 0;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 10px 10px 0px rgba(53,82,99,0.09);
  text-align: center;
  border-radius: 25px;
  text-align: left;
}
.sigma_pricing.pricing-3 .sigma_pricing-thumb{
  position: relative;
}
.sigma_pricing.pricing-3 img{
  width: 100%;
}
.sigma_pricing.pricing-3 .sigma_pricing-meta{
  position: absolute;
  bottom: -60px;
  left: 40px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 80px);
}
.sigma_pricing.pricing-3 .sigma_pricing-meta .sigma_pricing-price{
  background-color: #fff;
  border-radius: 25px;
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.sigma_pricing.pricing-3 .sigma_pricing-price-inner{
  margin: 0;
  line-height: 1;
  display: flex;
  align-items: center;
}
.sigma_pricing.pricing-3 .sigma_pricing-price-inner .currency{
  font-size: 18px;
}

.sigma_pricing.pricing-3 .sigma_pricing-price-inner .price{
  color: #313131;
}
.sigma_pricing.pricing-3 .sigma_pricing-type{
  margin: 0;
  font-size: 14px;
}
.sigma_pricing.pricing-3 .sigma_pricing-cycle{
  background-color: #f36525;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 5px 20px;
  border-radius: 35px;
}
.sigma_pricing.pricing-3 .sigma_pricing-info{
  padding: 80px 40px 40px;
  background-color: #f8f8f8;
}
.sigma_pricing.pricing-3 .sigma_pricing-info li{
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sigma_pricing.pricing-3 .sigma_pricing-info li + li{
  margin-top: 15px;
}
.sigma_pricing.pricing-3 .sigma_btn-custom.light{
  background-color: #fff;
}
.sigma_pricing.pricing-3 .sigma_btn-custom,
.sigma_pricing.pricing-3 .list-style-none{
  margin: 0;
}

/* 6.15. Contact */
.sigma_contact-info{
  padding: 0;
  height: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.sigma_contact-info h3{
  font-weight: 800;
}
.sigma_contact-info h6{
  margin-bottom: 5px;
}
.sigma_contact-info-item p{
  margin: 0;
}
.sigma_contact-info-item + .sigma_contact-info-item{
  margin-top: 20px;
}
.sigma_contact-info .sigma_sm a{
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  background-color: rgba(255,255,255,.1);
}

.sigma_contact-info .sigma_sm a:hover{
  background-color: #f36525;
}
.sigma_contact-bg{
  height: 100%;
}

.sigma_contact-info-item + .sigma_contact-info-item{
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #efefef;
}

.sigma_contact-info-item .sigma_sm li a{
  color: #777;
  font-size: 18px;
}
.sigma_contact-info-item .sigma_sm li a:hover{
  color: #f36525;
}

/*======================
7. Misc
========================*/
.slick-list {
    margin: 0 -15px;
}

.slick-slide {
    outline: none;
    margin: 0 15px;
}

/* Arrows */
.sigma_arrows {
    display: inline-flex;
    align-items: center;
}

.sigma_arrows .slick-arrow {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #313131;
    transition: .3s;
    cursor: pointer;
    color: #777;
}
.sigma_arrows.style-2{
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
}
.sigma_arrows.style-2 .slick-arrow{
  background-color: #f8f8f8;
}
.sigma_arrows .slick-arrow + .slick-arrow{
  margin-left: 10px;
}
.sigma_arrows .slick-arrow:hover {
    color: #fff;
    background-color: #f36525;
}
.sigma_arrows.sigma_arrows-transparent .slick-arrow:hover,
.sigma_arrows.sigma_arrows-transparent .slick-arrow{
  background-color: transparent;
  color: #fff;
}

.sigma_arrows.sigma_arrows-dark .slick-arrow:hover,
.sigma_arrows.sigma_arrows-dark .slick-arrow{
  color: #313131;
}
.sigma_arrows.sigma_arrows-dark .sigma_round-arrow .sigma_round-arrow-circle{
  color: #f36525;
  stroke: #f36525;
}
.sigma_arrows.sigma_arrows-dark .sigma_round-arrow .sigma_round-arrow-stroke{
  color: #313131;
  stroke: #313131;
}
.sigma_arrows.sigma_arrows-sm .sigma_round-arrow svg{
  width: 40px;
  height: 40px;
}
.sigma_arrows .sigma_round-arrow{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.sigma_arrows .sigma_round-arrow svg{
  width: 50px;
  height: 50px;
}
.sigma_arrows .sigma_round-arrow.prev-arrow{
  margin-right: 10px;
}
.sigma_arrows .sigma_round-arrow i{
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
}
.sigma_arrows .sigma_round-arrow .slick-arrow.slider-prev{
  margin: 0;
}

.sigma_arrows .sigma_round-arrow .sigma_round-arrow-stroke{
  fill: none;
  stroke: currentColor;
  stroke-width: 1;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  position: relative;
  z-index: -1;
  opacity: .5;
  color: #fff;
}
.sigma_arrows .sigma_round-arrow .sigma_round-arrow-circle{
  fill: none;
  stroke: currentColor;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  stroke-dashoffset: 700;
  stroke-dasharray: 700;
  stroke-width: 4px;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  stroke-linecap: butt;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
  color: #fff;
}
.sigma_arrows .sigma_round-arrow:hover .sigma_round-arrow-circle{
  stroke-dashoffset: 0;
  stroke-dasharray: 700;
}
.sigma_arrows-absolute-center .sigma_round-arrow{
  position: absolute;
  top: 50%;
  margin-right: 0;
  opacity: 0;
  visibility: hidden;
  transition: .3s;
}
.sigma_arrows-absolute-center .sigma_round-arrow.prev-arrow{
  left: 35px;
}
.sigma_arrows-absolute-center .sigma_round-arrow.next-arrow{
  right: 35px;
}
.section:hover .sigma_arrows-absolute-center .sigma_round-arrow.next-arrow{
  right: 15px;
  opacity: 1;
  visibility: visible;
}
.section:hover .sigma_arrows-absolute-center .sigma_round-arrow.prev-arrow{
  left: 15px;
  opacity: 1;
  visibility: visible;
}

.slick-prev,
.slick-next {
    background-color: #313131;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    color: #313131;
    transition: .3s;
    z-index: 9;
    opacity: 0;
    visibility: hidden;
}

.slick-slider:hover .slick-next,
.section:hover .slick-next {
    right: -55px;
    opacity: 1;
    visibility: visible;
}

.slick-slider:hover .slick-prev,
.section:hover .slick-prev {
    left: -55px;
    opacity: 1;
    visibility: visible;
}

.slick-prev:before,
.slick-next:before {
    color: #ffffff;
    font-family: "Font Awesome 5 Pro";
    content: "\f054";
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    font-weight: 900;
    text-rendering: auto;
    line-height: 1.7;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.slick-prev:before {
    content: "\f053";
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    color: #fff;
    background-color: #f36525;
}

.slick-prev:hover::before,
.slick-prev:focus::before,
.slick-next:hover::before,
.slick-next:focus::before {
    color: #fff;
}

/* Dots */
.slick-dots {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
    margin-top: 25px;
    justify-content: flex-start;
}

.slick-dots li {
    margin: 0 5px;
    text-align: center;
}

.slick-dots li button {
    font-size: 0;
    width: 30px;
    height: 7px;
    background-color: #fff;
    border: 0;
    opacity: .6;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    cursor: pointer;
    outline: none;
}

.slick-dots li.slick-active button {
  opacity: 1;
  width: 50px;
}

.light-dots .slick-dots li button{
  background-color: #fff;
}
.light-dots .slick-dots li.slick-active button {
    background-color: #f36525;
}


.slick-slide {
    outline: none;
}

/* custom Dots */
.slick-dots .sigma_round-dot{
  position: relative;
  cursor: pointer;
}
.slick-dots .sigma_round-dot::before{
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  transition: .3s;
  opacity: .6;
}
.slick-dots .sigma_round-dot svg{
  width: 30px;
  height: 30px;
}
.slick-dots .sigma_round-dot .sigma_round-dot-stroke{
  fill: none;
  stroke: currentColor;
  stroke-width: 1;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  position: relative;
  z-index: -1;
  opacity: .5;
  color: #fff;
}
.slick-dots .sigma_round-dot .sigma_round-dot-circle{
  fill: none;
  stroke: currentColor;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  stroke-dashoffset: 700;
  stroke-dasharray: 700;
  stroke-width: 2px;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  stroke-linecap: butt;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
  color: #fff;
}
.slick-dots .slick-active .sigma_round-dot-circle{
  stroke-dashoffset: 0;
  stroke-dasharray: 700;
}
.slick-dots .slick-active .sigma_round-dot::before,
.slick-dots .sigma_round-dot:hover::before{
  opacity: 1;
}

.slick-dots .sigma_round-dot.dot-dark .sigma_round-dot-circle,
.slick-dots .sigma_round-dot.dot-dark .sigma_round-dot-stroke{
  color: #313131;
}
.slick-dots .sigma_round-dot.dot-dark::before{
  background-color: #313131;
}

/* pagination */
.pagination {
    margin-top: 50px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
}

.pagination .page-item {
    margin: 0;
}

.pagination .page-item:last-child .page-link,
.pagination .page-item:first-child .page-link,
.pagination .page-item .page-link {
    border-radius: 50%;
}

.pagination .page-link {
  color: #313131;
  border: 0;
  background-color: #fff;
  border: 1px solid #efefef;
  border-radius: 50%;
  font-weight: 600;
  padding: 15px 20px;
  margin-left: 15px;
}

.pagination .page-item.active .page-link {
    background-color: #313131;
    border: 1px solid #313131;
}

.pagination .page-item:not(.active) .page-link:hover,
.pagination .page-item:not(.active) .page-link:focus {
    color: #f36525;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.tooltip {
    font-size: 13px;
}

.load-more {
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 50px;
    padding: 10px;
    border: 1px solid #efefef;
    border-radius: 8px;
    font-weight: 600;
    background-color: #fff;
}

.load-more:hover {
    background-color: #f8f8f8;
}

/* Progress */
.sigma_progress{
  position: relative;
  font-weight: 700;
  color: #313131;
}
.sigma_progress h6{
  margin: 0 0 5px;
  font-weight: 800;
}

.sigma_progress .sigma_progress-count{
  position: absolute;
  top: 0;
  transition: 3s;
}

.sigma_progress + .sigma_progress{
  margin-top: 30px;
}

.progress{
  background-color: #313131;
  height: 4px;
  border-radius: 0;
  overflow: visible;
}

.progress .progress-bar{
  position: relative;
  background-color: #f36525;
  transition: 3s;
}

.progress .progress-bar span{
  position: absolute;
  top: 50%;
  right: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #f36525;
  transform: translateY(-50%);
}

/* Accordion */
.accordion .card {
    margin-bottom: 0;
}
.accordion .card {
  background-color: #f8f8f8;
}

.accordion .card .card-header .btn {
  position: relative;
  background-color: #f8f8f8;
  border-bottom: 0;
  padding: .75rem 1.25rem;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #313131;
  font-weight: 600;
}

.accordion .card-header .btn[aria-expanded="true"] {
    background-color: #f36525;
    color: #fff;
}

.accordion .card-header .btn::after {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: 1.25rem;
    width: 10px;
    height: 2px;
    background-color: #848486;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    opacity: 1;
    z-index: 1;
    box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
}

.accordion .card-header .btn::before {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: 1.25rem;
    width: 10px;
    height: 2px;
    background-color: #848486;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    opacity: 1;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    z-index: 1;
    box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
}

.accordion .card-header .btn[aria-expanded="true"]::before {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    opacity: 0;
}

.accordion .card-header .btn[aria-expanded="true"]::after,
.accordion .card-header .btn[aria-expanded="true"]::before {
    background-color: #f36525;
}

.accordion .card-header .btn i {
    position: relative;
    top: 0;
    left: 0;
    margin-right: 10px;
    font-size: 20px;
}

.accordion .card-header .btn[aria-expanded="true"] i {
    color: #f36525;
}

.accordion .card {
    border: #f8f8f8;
}

.accordion.with-gap .card-header {
  border-radius: 0;
}

.accordion.with-gap .card {
    margin-bottom: 10px;
}
.accordion .card::before{
  content: '';
  position: absolute;
  top: 8px;
  right: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  display: block;
  z-index: 1;
}
.accordion.with-gap .card:last-child {
    margin-bottom: 0;
}

.accordion.with-gap .card:first-of-type,
.accordion.with-gap .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 1px solid #efefef;
}

/* Rating */
.sigma_rating-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.sigma_rating-wrapper>span {
    margin-left: 10px;
    color: #848486;
    font-size: 13px;
}

.sigma_rating {
    margin-bottom: 5px;
}

.sigma_rating i {
    margin-right: 5px;
    font-size: 12px;
    color: #848486;
}

.sigma_rating i.active {
    color: #ffa80a;
}

.mt-negative {
    margin-top: -150px;
    position: relative;
    z-index: 1;
}
.mt-negative-md{
  margin-top: -90px;
  position: relative;
  z-index: 1;
}
.mt-negative-sm{
  margin-top: -30px;
}
.mt-negative-sm + .mt-negative-sm{
  margin-top: -60px;
}

.mt-20{
  margin-top: 20px;
}
.mt-n-20{
  margin-top: -20px;
}

.mb-negative{
  margin-bottom: -85px;
  z-index: 11;
  position: relative;
  display: block;
}
.mb-negative + .sigma_footer{
  padding-top: 85px;
}

.section.section-padding.mt-negative,
.section.mt-negative{
  padding-top: 0;
}

.custom_flex {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.sigma_block-box {
    padding: 45px 40px;
    border-radius: 0;
    margin-bottom: 30px;
}

.vertical-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.vertical-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sigma_block-box h5 {
    font-weight: 600;
    margin-bottom: 5px;
}

.sigma_block-box h4 {
    margin-bottom: 10px;
}
.sigma_block-box img{
  width: 30px;
  border-radius: 50%;
  margin-right: 5px;
}

.sigma_block-box .sigma_audio-player{
  margin-bottom: 0;
}
.sigma_block-box .sigma_block-content {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.sigma_block-box .sigma_block-content li{
  margin: 0;
}
.sigma_block-box .sigma_block-content li + li{
  margin-top: 10px;
}

/*Video box*/
.sigma_video-popup-wrap{
  position: relative;
}
.sigma_video-popup-wrap .sigma_video-popup{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.sigma_video-popup{
  position: relative;
  width: 80px;
  height: 80px;
  background-color: #f36525;
  color: #fff;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.sigma_video-popup::before{
  content: '';
  position: absolute;
  top: -10px;
  left: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  border: 2px solid #fff;
  animation-name: pulseInOut;
  opacity: 0;
  border-radius: 50%;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
.sigma_video-popup::after{
  content: '';
  position: absolute;
  top: -10px;
  left: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  border: 2px solid #fff;
  animation-name: pulseInOutSM;
  opacity: 0;
  border-radius: 50%;
  animation-duration: 3.5s;
  animation-iteration-count: infinite;
}
.sigma_video-popup.bg-white{
  color: #f36525;
}
.sigma_video-popup.bg-white:hover{
  background-color: #f36525 !important;
  color: #fff;
}

.sigma_video-popup.popup-lg{
  width: 140px;
  height: 140px;
  font-size: 20px;
}
.sigma_video-popup.popup-sm{
  width: 50px;
  height: 50px;
}
.sigma_video-popup.popup-dark{
  background-color: #2d2d2d;
  color: #fff;
}

.sigma_video-popup.secondary{
  color: #313131;
}
.sigma_video-popup.secondary::after{
  background-color: #313131;
}

.sigma_video-popup:hover{
  color: #fff;
}

/*sigma_testimonials*/
.sigma_testimonial.sigma_box{
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 50px;
}
.sigma_testimonial.sigma_box .slick-dots{
  position: absolute;
  bottom: 0;
  right: 0;
}
.sigma_testimonial.sigma_box .slick-dots .sigma_round-dot-circle{
  stroke: #f36525;
  stroke-width: 5px;
}
.sigma_testimonial .sigma_testimonial-inner>i{
    font-size: 50px;
    margin-bottom: 25px;
    line-height: 1;
}
.sigma_testimonial .sigma_testimonial-inner>p{
    line-height: 30px;
    margin: 0 0 40px;
}
.sigma_testimonial .sigma_testimonial-inner cite{
    font-size: 15px;
    font-weight: 700;
}
.sigma_testimonial .sigma_testimonial-author{
  display: flex;
  align-items: center;
}
.sigma_testimonial .sigma_testimonial-author img{
  margin-right: 15px;
  border-radius: 50%;
  width: 75px;
}
.sigma_testimonial .sigma_testimonial-author h6{
  margin-bottom: 0;
}

/* Style 2 */
.sigma_testimonial.style-2 .sigma_testimonial-inner{
  position: relative;
  padding: 30px;
  background-color: #fff;
  box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
  margin-bottom: 30px;
}
.sigma_testimonial.style-2 .sigma_testimonial-inner::before{
  position: absolute;
  bottom: 0;
  right: 10px;
  font-size: 160px;
  content: "\f10e";
  opacity: .1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.sidebar-widget.widget-ad{
  position: relative;
}
.sidebar-widget.widget-ad a div{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  min-width: 80%;
  font-weight: 600;
  background-color: #f36525;
  padding: 20px;
  text-align: center;
  font-size: 24px;
  line-height: 1.4;
}
.sidebar-widget.widget-ad a div span{
  display: block;
  text-transform: none;
}

.sidebar-widget.widget-ig [class*="col-"]{
  padding: 0 2.5px;
}
.sidebar-widget.widget-ig .row{
  margin-left: -5px;
  margin-right: -5px;
}



/* counter */
.sigma_counter{
  margin-bottom: 30px;
}
.sigma_counter i{
  color: #f36525;
  font-size: 46px;
  line-height: 1;
  display: block;
  margin-bottom: 20px;
}
.sigma_counter h3,
.sigma_counter b{
  margin: 0;
}
.sigma_counter .counter{
  font-size: 40px;
}
.sigma_counter p{
  margin: 0;
  font-weight: 600;
  font-size: 16px;
}
.sigma_counter > span{
  display: block;
  line-height: 1;
  margin-bottom: 5px;
}
.sigma_counter span span{
  font-size: 16px;
  font-weight: 600;
}

/* Services */
.sigma_service{
  display: block;
  background-color: #fff;
  border: 1px solid #efefef;
  padding: 40px;
  text-align: center;
  transition: .3s;
  margin-bottom: 30px;
}

.sigma_service.border .sigma_service-thumb{
  position: relative;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  margin: 0 auto 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #efefef;
}
.sigma_service.border .sigma_service-thumb::before{
  content: '';
  position: absolute;
  top: 10px;
  right: 17px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #f36525;
  transition: .3s;
}
.sigma_service.border .sigma_service-thumb::after{
  content: '';
  position: absolute;
  bottom: 10px;
  left: 17px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #f36525;
  transition: .3s;
}

.sigma_service.primary-bg:hover,
.sigma_service.primary-bg{
  background-color: #f36525;
  border: 0;
}
.sigma_service.secondary-bg:hover,
.sigma_service.secondary-bg{
  background-color: #313131;
  border: 0;
}

.sigma_service.border.primary-bg .sigma_service-thumb::after,
.sigma_service.border.primary-bg .sigma_service-thumb::before,
.sigma_service.border.secondary-bg .sigma_service-thumb::after,
.sigma_service.border.secondary-bg .sigma_service-thumb::before{
  background-color: #fff;
}

.sigma_service.border:hover .sigma_service-thumb::after{
  bottom: -4px;
  left: 47px;
}
.sigma_service.border:hover .sigma_service-thumb::before{
  top: -4px;
  right: 47px;
}

.sigma_service.bg-white{
  border: 0;
}

.sigma_service.style-1.bg-white:hover .btn-link,
.sigma_service.style-1.bg-white:hover p,
.sigma_service.style-1.bg-white:hover h5{
  color: #313131;
}
.sigma_service.style-1.bg-white:hover i{
  color: #f36525;
}

.sigma_service i{
  font-size: 60px;
  color: #f36525;
  line-height: 1;
  transition: .3s;
}
.sigma_service .btn-link{
  margin-top: 20px;
  display: block;
}
.sigma_service .btn-link i{
  color: #313131;
  margin-left: 5px;
}
.sigma_service-thumb{
  margin-bottom: 40px;
}
.sigma_service h5{
  margin-bottom: 10px;
  transition: .3s;
}
.sigma_service p{
  margin: 0;
  transition: .3s;
}
.sigma_service:hover{
  background-color: #f36525;
  transform: translateY(-3px);
}
.sigma_service.style-1:hover .btn-link,
.sigma_service.style-1:hover i,
.sigma_service.style-1:hover p,
.sigma_service.style-1:hover h5{
  color: #fff;
}

/* Style 2 & 3 */
.sigma_service.style-2 h5{
  margin-bottom: 10px;
}
.sigma_service.style-2 i{
  position: absolute;
  bottom: -30px;
  right: 30px;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #777;
  background-color: #fff;
  font-size: 24px;
  border-radius: 50%;
  box-shadow: 0 3px 24px rgba(0,0,0,.04);
}
.sigma_service.style-2:hover i{
  background-color: #f36525;
  color: #fff;
}

.sigma_service.style-3,
.sigma_service.style-2{
  background-color: transparent;
  box-shadow: none;
  text-align: left;
  padding: 0;
}

.sigma_service.style-3 .sigma_service-thumb,
.sigma_service.style-2 .sigma_service-thumb{
  position: relative;
  margin: 0;
  z-index: 1;
}

.sigma_service.style-3 i{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  transition: .3s;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, -50%);
}

.sigma_service.style-3 .sigma_service-thumb::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.4);
  transition: .3s;
  opacity: 0;
  visibility: hidden;
}

.sigma_service.style-3:hover i,
.sigma_service.style-3:hover .sigma_service-thumb::before,
.sigma_service.style-2:hover i,
.sigma_service.style-2:hover .sigma_service-thumb::before{
  opacity: 1;
  visibility: visible;
}

.sigma_service.style-3 h5{
  margin-bottom: 20px;
}

.sigma_service.style-3 p,
.sigma_service.style-2 p{
  margin: 0;
}

.sigma_service.style-3 .sigma_service-body,
.sigma_service.style-2 .sigma_service-body{
  padding: 30px;
}
.sigma_service.style-3{
  border: 0;
}
.sigma_service.style-3 .sigma_service-body{
  position: relative;
  background-color: #fff;
  box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
  border: 0;
}
.sigma_service.style-3 .sigma_service-body::before{
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 3px;
  background-color: #f36525;
  transition: .3s;
}
.sigma_service.style-3:hover .sigma_service-body::before{
  width: 100%;
}


/* Style 4 */
.sigma_service.style-4{
  text-align: left;
}
.sigma_service.style-4 .sigma_service-footer{
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #efefef;
}
.sigma_service.style-4 i{
  margin-bottom: 20px;
  display: block;
}
.sigma_service.style-4 .sigma_service-footer ul{
  margin: 0;
}
.sigma_service.style-4 .sigma_service-footer ul li{
  position: relative;
  color: #777;
  display: flex;
  font-size: 14px;
  align-items: center;
  margin: 0;
}
.sigma_service.style-4 .sigma_service-footer ul li + li{
  margin-top: 10px;
}
.sigma_service.style-4 .sigma_service-footer ul li::before{
  content: '-';
  margin-right: 10px;
  transition: .3s;
  color: #f36525;
  display: block;
}
.sigma_service.style-4:hover *{
  color: #fff;
}
.sigma_service.style-4:hover .sigma_service-footer ul li{
  color: #fff;
}
.sigma_service.style-4:hover .sigma_service-footer ul li::before{
  color: #fff;
}

.sigma_service.style-4.dark{
  background-color: #313131;
  border-color: rgba(255,255,255,.1);
}
.sigma_service.style-4.dark .sigma_service-footer{
  border-top-color: rgba(255,255,255,.1);
}
.sigma_service.style-4.dark i{
  color: #f36525;
}
.sigma_service.style-4.dark h5{
  color: #fff;
}
.sigma_service.style-4.dark *{
  color: #d2d2d2;
}
.sigma_service.style-4.dark .sigma_service-footer ul li{
  color: #d2d2d2;
}

/* Style 5 */
.sigma_service.style-5{
  text-align: left;
  padding: 30px;
}
.sigma_service.style-5:hover{
  background-color: transparent;
}
.sigma_service.style-5 > i{
  display: block;
  margin-bottom: 20px;
}
.sigma_service.style-5 span{
  color: #777;
}
.sigma_service.style-5 h5{
  font-size: 22px;
}
.sigma_service.style-5 .sigma_service-link i{
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #313131;
  box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
  margin-top: 50px;
  transition: .3s;
}
.sigma_service.style-5:hover .sigma_service-link i{
  background-color: #f36525;
  color: #fff;
}

/* Client Reviews & Clients & Experience */
.sigma_experience{
  padding: 0 20px;
}
.sigma_experience-item{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sigma_experience-item strong{
  min-width: 170px;
}
.sigma_experience-item strong + span{
  margin-right: auto;
}
.sigma_experience-item + .sigma_experience-item{
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #efefef;
}
.sigma_client-review{
  margin-bottom: 30px;
}
.sigma_box{
  padding: 50px 50px 20px;
  border-radius: 35px;
  margin: 50px 0;
  background-color: #fff;
  box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
}
.sigma_box-lg{
  padding: 40px;
}
.sigma_box.box-lg{
  padding: 40px;
}
.sigma_box-absolute{
  position: absolute;
  top: -15px;
  left: -50px;
  width: calc(100% + 100px);
  z-index: 1;
}

.sigma_box-progress{
  position: relative;
}
.sigma_box-progress.dark{
  background-color: #313131;
}
.sigma_box-progress.dark h3{
  color: #fff;
  margin-bottom: 30px;
}
.sigma_box-progress.dark b{
  color: #fff;
}
.sigma_box-progress.dark .sigma_btn-custom{
  color: #fff;
  border-color: #5a5a5a;
}
.sigma_box-progress.dark .sigma_round-button .sigma_round-button-stroke{
  stroke: #5a5a5a;
}
.sigma_box-progress i{
  font-size: 110px;
  opacity: .2;
  position: absolute;
  line-height: 1;
  top: 20px;
  right: 20px;
}

.sigma_client{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  transition: .3s;
  max-width: 90%;
  margin: 0 auto 30px;
}

.sigma_client:hover{
  filter: drop-shadow(2px 4px 6px rgba(0,0,0,.4));
}
.sigma_client img{
  transition: .3s;
}
.sigma_client:hover img{
  filter: drop-shadow(2px 4px 6px rgba(0,0,0,.1));
}

/* Portfolio */
.sigma_portfolio-item{
  position: relative;
  margin-bottom: 30px;
  cursor: pointer;
}
.sigma_portfolio-item img{
  width: 100%;
}
.sigma_portfolio-item span{
  color: #fff;
  transform: translateY(-10px);
  opacity: 0;
  visibility: hidden;
  transition: .3s;
}

.sigma_portfolio-item .sigma_portfolio-item-content{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: .3s;
  padding: 20px;
}
.sigma_portfolio-item:hover .sigma_portfolio-item-content{
  background-color: rgba(0,0,0,.4);
}
.sigma_portfolio-item .sigma_portfolio-item-content-inner{
  margin-top: auto;
  transform: translateY(10px);
  opacity: 0;
  visibility: hidden;
  transition: .3s;
}
.sigma_portfolio-item .sigma_portfolio-item-content-inner h5{
  margin: 0 0 10px;
}
.sigma_portfolio-item .sigma_portfolio-item-content-inner p{
  margin: 0;
  color: #d2d2d2;
}
.sigma_portfolio-item .sigma_portfolio-item-content-inner h5 a{
  color: #fff;
}
.sigma_portfolio-item .sigma_portfolio-item-content-inner h5 a:hover{
  color: #f36525;
}
.sigma_portfolio-item .sigma_portfolio-item-content i{
  font-size: 15px;
  position: absolute;
  top: 20px;
  left: 20px;
  color: #fff;
  transition: .3s;
  z-index: 3;
  background-color: #f36525;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
}

.sigma_portfolio-item:hover .sigma_portfolio-item-content i{
  opacity: 1;
  visibility: visible;
  bottom: 20px;
}

.sigma_portfolio-item:hover  span,
.sigma_portfolio-item:hover .sigma_portfolio-item-content-inner{
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.filter-items{
  position: relative;
  max-width: 700px;
  margin: 0 auto 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #efefef;
}
.filter-items.left{
  margin-left: 0;
}
.filter-items h5{
  position: relative;
  transition: .3s;
  padding: 20px 40px;
  color: #777;
  border-bottom: 3px solid transparent;
  margin: 0;
  cursor: pointer;
}
.filter-items h5 + h5::before{
  content: '';
  width: 2px;
  height: 15px;
  background-color: #efefef;
  display: block;
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
}
.filter-items h5.active{
  border-bottom-color: #f36525;
  color: #f36525;
}
.filter-items h5:not(.active):hover{
  color: #313131;
}

/* Style 2 and 3 */
.sigma_portfolio-item.style-3 .sigma_portfolio-item-content,
.sigma_portfolio-item.style-2 .sigma_portfolio-item-content{
  position: relative;
  opacity: 1;
  visibility: visible;
  top: auto;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  height: auto;
  left: auto;
  box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
  background-color: #fff;
}
.sigma_portfolio-item.style-3:hover .sigma_portfolio-item-content,
.sigma_portfolio-item.style-2:hover .sigma_portfolio-item-content{
  background-color: #fff;
}

.sigma_portfolio-item.style-3 .sigma_portfolio-item-content i,
.sigma_portfolio-item.style-2 .sigma_portfolio-item-content i{
  position: relative;
  top: auto;
  left: auto;
  opacity: 1;
  visibility: visible;
  width: auto;
  height: auto;
  color: #777;
  background-color: transparent;
}

.sigma_portfolio-item.style-3:hover .sigma_portfolio-item-content i,
.sigma_portfolio-item.style-2:hover .sigma_portfolio-item-content i{
  bottom: auto;
  transform: translateX(5px);
  color: #f36525;
}

.sigma_portfolio-item.style-3 .sigma_portfolio-item-content-inner,
.sigma_portfolio-item.style-2 .sigma_portfolio-item-content-inner{
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  margin-top: 0;
}

.sigma_portfolio-item.style-3 .sigma_portfolio-item-content-inner h5,
.sigma_portfolio-item.style-2 .sigma_portfolio-item-content-inner h5{
  margin: 0;
  font-size: 16px;
}

.sigma_portfolio-item.style-3 .sigma_portfolio-item-content-inner h5 a,
.sigma_portfolio-item.style-2 .sigma_portfolio-item-content-inner h5 a{
  color: #313131;
}

.sigma_portfolio-item.style-3 .sigma_portfolio-item-content{
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-top: -45px;
}

/* Style 4 and 5 and 6 */
.sigma_portfolio-item.style-4,
.sigma_portfolio-item.style-5,
.sigma_portfolio-item.style-6{
  overflow: hidden;
  position: relative;
}
.sigma_portfolio-item.style-4 .sigma_portfolio-item-content-inner,
.sigma_portfolio-item.style-5 .sigma_portfolio-item-content-inner,
.sigma_portfolio-item.style-6 .sigma_portfolio-item-content-inner{
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  padding-right: 40px;
}
.sigma_portfolio-item.style-4 .sigma_portfolio-item-content i,
.sigma_portfolio-item.style-5 .sigma_portfolio-item-content i,
.sigma_portfolio-item.style-6 .sigma_portfolio-item-content i{
  opacity: 1;
  visibility: visible;
  top: auto;
  left: auto;
  bottom: 20px;
  right: 20px;
  border-radius: 50%;
  background-color: rgba(255,255,255,.2);
  color: #fff;
}
.sigma_portfolio-item.style-4 .sigma_portfolio-item-content i:hover,
.sigma_portfolio-item.style-5 .sigma_portfolio-item-content i:hover{
  background-color: #f36525;
}
.sigma_portfolio-item.style-4 .sigma_portfolio-item-content{
  background-image: linear-gradient(to top, rgba(0,0,0,.7) 20%, transparent 80%);
}
.sigma_portfolio-item.style-4:hover .sigma_portfolio-item-content,
.sigma_portfolio-item.style-5:hover .sigma_portfolio-item-content,
.sigma_portfolio-item.style-6:hover .sigma_portfolio-item-content{
  background-color: transparent;
}
.sigma_portfolio-item.style-4 img,
.sigma_portfolio-item.style-5 img,
.sigma_portfolio-item.style-6 img{
  transition: .3s;
}
.sigma_portfolio-item.style-4:hover img{
  transform: scale(1.1) rotate(2deg);
}
.sigma_portfolio-item.style-4 h5,
.sigma_portfolio-item.style-5 h5,
.sigma_portfolio-item.style-6 h5{
  font-size: 18px;
}

.sigma_portfolio-item.style-5 .sigma_portfolio-item-content,
.sigma_portfolio-item.style-6 .sigma_portfolio-item-content{
  position: relative;
  top: auto;
  left: auto;
  width: auto;
  height: auto;
  padding: 20px 0 0;
}
.sigma_portfolio-item.style-5 .sigma_portfolio-item-content-inner,
.sigma_portfolio-item.style-6 .sigma_portfolio-item-content-inner{
  padding-right: 0;
}
.sigma_portfolio-item.style-5 .sigma_portfolio-item-content h5 a,
.sigma_portfolio-item.style-6 .sigma_portfolio-item-content h5 a{
  color: #313131;
}
.sigma_portfolio-item.style-5 .sigma_portfolio-item-content i{
  background-color: #fff;
  color: #777;
  bottom: 60px;
  right: 20px;
  box-shadow: 0 3px 24px rgba(0,0,0,.04);
}
.sigma_portfolio-item.style-5 .sigma_portfolio-item-content i:hover{
  background-color: #f36525;
  color: #fff;
}
.sigma_portfolio-item.style-6{
  overflow: visible;
}
.sigma_portfolio-item.style-6 .sigma_portfolio-item-content {
  border-radius: 35px;
  padding: 20px;
  text-align: center;
  background-color: #fff;
  width: calc(100% - 40px);
  margin: -35px auto 0;
  box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
}
.sigma_portfolio-item.style-6 .sigma_portfolio-item-content i{
  position: relative;
  bottom: auto;
  right: 30px;
  color: #fff;
  background-color: transparent;
  opacity: 0;
  visibility: hidden;
  width: auto;
  height: auto;
}

.sigma_portfolio-item.style-6 .sigma_portfolio-item-content h5{
  margin: 0;
}
.sigma_portfolio-item.style-6 .sigma_portfolio-item-content-inner h5 a{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.sigma_portfolio-item.style-6:hover .sigma_portfolio-item-content{
  background-color: #f36525;
}
.sigma_portfolio-item.style-6:hover .sigma_portfolio-item-content h5 a{
  color: #fff;
}
.sigma_portfolio-item.style-6:hover .sigma_portfolio-item-content i{
  right: -20px;
  opacity: 1;
  visibility: visible;
}

/* Portfolio Slider */
.sigma_portfolio-slider .slick-list{
  overflow: visible;
}

.sigma_portfolio-slider .slick-list{
  margin: 0 -35px;
}
.sigma_portfolio-slider .slick-slide{
  margin: 0 35px;
}

.sigma_portfolio-slider img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sigma_portfolio-slider .slick-list .slick-active + .slick-slide .row{
  flex-direction: row-reverse;
}

.sigma_portfolio-section .sigma_arrows{
  position: absolute;
  bottom: 40px;
  left: 15px;
}
.sigma_portfolio-slider .portfolio-item .section-title{
  max-width: 100%;
  margin-bottom: 0;
}
.sigma_portfolio-slider .portfolio-item .section-title .title{
  font-size: 32px;
}

.sigma_portfolio-slider-2 .slick-list{
  overflow: visible;
}

/* Portoflio Details */
.sigma_post-single-thumb .sigma_box{
  position: absolute;
  top: -100px;
  right: 40px;
  margin: 0;
  min-width: 300px;
}

.sigma_list-item + .sigma_list-item{
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #efefef;
}
.sigma_list-item label{
  display: block;
  margin: 0;
}
.sigma_list-item .sigma_sm li a{
  color: #777;
}
.sigma_list-item .sigma_sm li a:hover{
  color: #f36525;
}

/*======== Shortcodes Css, added by saime 12/3/2020 =========*/
/* About */

.about-sec.style-2 .about-wrapper{
  position: relative;
  text-align: left;
}

.about-sec.style-2 .section-title h5.subtitle{
  font-size: 26px;
  color: #f36525;
  margin-bottom: 0;
}

.about-sec.style-2 .about-content{
  margin-top: 45px;
}

.about-sec.style-2 .about-content ul{
  margin-top: 30px;
  margin-bottom: 0;
}

.about-sec.style-2 .about-content ul li{
display: flex;
align-items: center;
 color: #1D2228;
 font-size: 16px;
 font-weight: 600;
}

.about-sec.style-2 .about-content ul li + li{
  margin-top: 15px;
}

.about-sec.style-2 .about-content ul li span{
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #f36525;
  color: #f36525;
  margin-right: 20px;
}

.about-sec.style-2 .about-content .icon-box{
  background-color: #f4f4f5;
  padding: 50px;
  border-radius: 8px;
}

.about-sec.style-2 .about-content .icon-box i{
  font-size: 60px;
  color: #f36525;
  line-height: 1;
  display: inline-block;
}

.about-sec.style-2 .about-content .icon-box p{
  color: #1D2228;
  font-size: 18px;
  font-weight: 600;
  margin-top: 25px;
  margin-bottom: 0;
}

.about-sec.style-2 .about-content .user-column{
  display: flex;
  align-items: center;
    padding-left: 50px;
    border-left: 6px solid #f36525;
    margin-top: 45px;
}

.about-sec.style-2 .about-content .user-column .user-image{
  border: 1px solid #e1e1e1;
  padding: 6px;
  border-radius: 50%;
  margin-right: 30px;
}
.about-sec.style-2 .about-content .user-column .user-image img{
  border-radius: 50%;
  width: 65px;
}

@media(max-width: 1199px) and (min-width: 992px){

  .about-sec.style-2 .about-content .icon-box{
    padding: 50px 30px;
  }

}

@media (max-width: 991px) {

  .about-sec.style-2 .section-title-wrap h3.subtitle{
  font-size: 22px;
  color: #f36525;
  margin-bottom: 0;
}

.about-sec.style-2 .section-title{
  margin-top: 30px;
  margin-bottom: 0;
}

.about-sec.style-2 .section-title-wrap {
    margin-top: 50px;
}
}

@media (max-width: 768px){

  .about-sec.style-2 .about-content .icon-box{
    margin-top: 45px;
  }
}



/* Project Sec */

.project-sec .image-wrapper{
  position: relative;
}

.project-sec .container .row{
  margin-bottom: 120px;
}

.project-sec .container .row:last-child{
  margin-bottom: 0;
}
.project-sec .image-wrapper .sigma_dots.dots-2{
    bottom: -54px;
    left: -50px;
    top: auto;
    width: 50%;
    height: 70%;
}
.project-sec .image-wrapper.right-aligned .sigma_dots.dots-2{
    right: -50px;
    left: auto;
}

.project-sec .content-area h4.title:before{
    content: "";
    position: absolute;
    left: -80px;
    top: 24px;
    width: 72px;
    height: 2px;
    z-index: 1;
    background-color: #f36525;
}

.project-sec .content-area.right-aligned h4.title:before{
    right: -80px;
    left: auto;
}

.project-sec .content-area p{
  margin-bottom: 30px;
}

@media (max-width: 991px) {

  .project-sec .content-area h4.title:before{
    content: none;
  }

  .project-sec .content-area{
    margin-top: 50px;
  }

  .project-sec .container .row.column-reverse{
    flex-direction: column-reverse;
  }
}


/* fun-facts */

.fun-facts .sigma_counter{
  flex-direction: row;
  text-align: left;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.fun-facts .sigma_counter .icon-box{
  color: #fff;
  font-size: 55px;
  margin-right: 25px;
}

.fun-facts .sigma_counter h5{
  font-size: 16px;
  margin: 8px 0 0;
  color: rgba(255, 255, 255, 0.7);
}

/* features sec */

.features-sec .content-wrapper{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  background-color: #1c1c1c;
  height: 100%;
  padding: 70px 120px;
}

.features-sec .content-wrapper h4{
  color: #fff;
  font-size: 34px;
  margin-bottom: 0;
}

.features-sec .content-wrapper p{
  color: rgba(255, 255, 255, 0.7);
  font-size: 18px;
  margin: 35px 0;
}

.features-sec .content-wrapper ul{
  margin-bottom: 35px;
}

.features-sec .content-wrapper ul li i{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: inline-flex;
    border: 1px solid #efefef;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
}

.features-sec .content-wrapper ul li{
    color: #fff;
    font-size: 16px;
    margin: 15px 0 0;
}

.features-sec .content-wrapper ul li:first-child{
  margin-top: 0;
}

.features-sec .image-wrapper{
  position: relative;
  height: 100%;
}
.features-sec .image-wrapper img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.features-sec .image-wrapper .icon-blocks{
  display: flex;
  align-items: center;
  background-color: #f36525;
  padding: 15px 20px;
  position: absolute;
  bottom: 54px;
  left: -60px;
}
.features-sec .image-wrapper .icon-blocks i{
  display: inline-block;
  color: #fff;
  font-size: 50px;
  margin-right: 10px;
}

.features-sec .image-wrapper .icon-blocks span{
  color: #fff;
  font-size: 24px;
  line-height: 1.3;
  font-weight: 600;
}

@media(max-width: 1199px) and (min-width: 991px){

  .features-sec .content-wrapper{
    padding: 70px 50px;
  }
}

@media (max-width: 768px) {

   .features-sec .content-wrapper{
    padding: 70px 50px;
  }
  .features-sec .content-wrapper h4{
    font-size: 30px;
  }
}

@media (max-width: 575px) {
.features-sec .content-wrapper{
   padding: 70px 20px;
  }
}



/* Gallery Sec */

.gallery-sec .gallery-content{
  padding: 40px 36px;
}

.gallery-content h5{
  margin-bottom: 20px;
}

.gallery-image{
  width: 100%;
  height: 100%;
  position: relative;
}
.gallery-image.with-overlay:before{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(49, 49, 49, 0.5);
}

.gallery-image.with-overlay .video-wrapper{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 991px) {

  .gallery-image.with-overlay{
    height: 307px;
  }
}

@media (max-width: 768px) {

  .gallery-image{
    height: 307px;
  }
}



/* services */

.service-sec{
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.service-box.style-2{
  position: relative;
  overflow: hidden;
  padding: 50px 30px;
  box-shadow: 4px 3px 24px rgb(223 227 234 / 74%);
  border-radius: 8px;
  background-color: #fff;
}

.service-box.style-2:before{
  content: "";
  position: absolute;
  right: -35px;
  bottom: -35px;
  width: 100px;
  height: 100px;
  background-color: #f36525;
  border-radius: 50%;
  transition: 0.3s;
}

.service-box.style-2:hover:before{
 right: -25px;
 bottom: -25px;
}

.service-box.style-2 .icon-box i{
  margin-bottom: 0;
  margin-right: 20px;
  color: #f36525;
  font-size: 65px;
  line-height: 1;
  display: block;
}

.service-box.style-2 .descr-box h3{
  margin-bottom: 0;
  font-size: 24px;
}
.service-box.style-2 .descr-box h3 a {
    color: #1D2228;
}

.service-box.style-2 .extra-info{
  margin: 25px 0;
}

.service-box.style-2 .service-link a{
  color: #1D2228;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.service-box.style-2 .service-link a i{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border:1px solid #f36525;
  color: #f36525;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  transition: 0.3s;
}

.service-box.style-2 .service-link a:hover i{
  background-color: #f36525;
  color: #fff;
}


/*  Video  */

.secondary-title{
    color: #f36525;
    display: block;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 600;
}

.video-style-3{
  margin-top: 90px;
}

.video-style-3 .skill-wrapper .section-title{
  margin-bottom: 40px;
}

.video-style-3 .skill-wrapper{
    background-color: #fff;
    padding: 40px;
    position: relative;
    margin: -200px 0 0;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
}

.video-style-3 .skill-wrapper .progress .progress-bar span{
  border-radius: 0;
  width: 4px;
}

.video-style-3 .skill-wrapper .video-player-trigger{
    position: absolute;
    left: -90px;
    top: 0;
}

.video-style-3 .skill-wrapper .video-player-trigger a{
    background-color: #f36525;
    color: #fff;
    width: 90px;
    height: 90px;
    border-radius: 0;
}

.video-style-3 .skill-wrapper .video-player-trigger a:after,
.video-style-3 .skill-wrapper .video-player-trigger a:before{
  content: none;
}

.video-style-3 .video-player-trigger a:hover{
  background-color: #313131;
}

@media (max-width: 991px) {

  .video-style-3 .skill-wrapper{
    margin: 60px 0 0;
    box-shadow: none;
  }

  .video-style-3 .skill-wrapper .video-player-trigger{
    top: -45px;
    right: 0;
    left: auto;
  }
}

@media (max-width: 768px) {

  .video-style-3 .skill-wrapper{
    padding: 25px;
  }

}

/*  Gallery-sec */

.gallery-slider{
  border-top: 2px solid #E5E5E6;
  border-bottom: 2px solid #E5E5E6;
  padding: 20px 0;
}
.gallery-slider .image-popup{
  position: relative;
  display: block;
}
.gallery-slider .slick-slide{
  margin: 0;
}

.gallery-slider.slick-slider .slick-prev,
.gallery-slider.slick-slider .slick-next{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border:2px solid #fff;
  z-index: 1;
  color: #fff;
}

.gallery-slider.slick-slider .slick-prev:before,
.gallery-slider.slick-slider .slick-next:before{
  content: "";
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  opacity: 1;
}

.gallery-slider.slick-slider .slick-prev:before{
  content: "\f060";
}
.gallery-slider.slick-slider .slick-next:before{
  content: "\f061";
}

.gallery-slider.slick-slider .slick-prev{
  left: 25px;
}
.gallery-slider.slick-slider .slick-next{
  right: 25px;
}

.gallery-slider .slick-dots{
    justify-content: center;
    margin-top: 40px;
  }

  .gallery-slider .slick-dots li button{
    background-color: #e1e1e1;
  }
  .gallery-slider .slick-dots li.slick-active button{
    background-color: #f36525;
  }

  .gallery-slider .gallery-thumb,
  .gallery-slider .gallery-thumb img{
    border-radius: 0;
  }
  .gallery-slider .gallery-thumb:before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #1D2228;
    opacity: 0;
    transition: 0.3s;
  }
  .gallery-slider .gallery-thumb:after{
    content: "\f002";
    position: absolute;
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255,255,255,0.6);
    color: #fff;
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 16px;
    opacity: 0;
    transition: 0.3s;
    z-index: 1;
    visibility: hidden;
}
 .gallery-slider .gallery-thumb:hover:after {
    opacity: 1;
    visibility: visible;
    top: 50%;
}
  .gallery-slider .gallery-thumb:hover:before{
    opacity: 0.5;
}

@media (max-width: 768px){
  .gallery-slider{
    padding: 0;
    border: 0;
  }
}



/*  Video style 2 */

.video-style-2.section{
  padding: 200px 0 300px;
}

.video-style-2 .video-player-trigger{
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-style-2 .video-player-trigger a{
  width: 100px;
  height: 100px;
  background-color: #f36525;
  color: #fff;
  font-size: 25px;
}

.video-style-2 .video-player-trigger a:hover{
  background-color: #313131;
}

/*  mission-vision */

.mission-vision{
  margin-top: -90px;
}

.mission-vision .mission-box{
  display: flex;
  align-items: center;
  padding: 40px;
  box-shadow: 4px 3px 24px rgb(223 227 234 / 74%);
  border-radius: 8px;
}

.mission-box .icon-box i{
  font-size: 75px;
  color: #1D2228;
  display: inline-block;
  line-height: 1;
  margin-right: 35px;
}

.mission-box .descr-box h4{
  margin-bottom: 14px;
}

@media (max-width: 576px){

  .mission-vision .mission-box{
    flex-direction: column;
    align-items: flex-start;
  }

  .mission-box .icon-box i{
    margin-bottom: 28px;
    margin-right: 0;
  }
}


/* What We Do */

.what-we-do .content-box{
  background-color: #fff;
  box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
  padding: 80px;
  position: relative;
  margin-left: -150px;
  margin-top: 130px;
}

.what-we-do .content-box p.extra-info{
  margin: 50px 0;
}

.what-we-do .progress{
  background-color: #efefef;
}

@media (max-width: 991px) {

  .what-we-do .content-box{
    margin-left: 0;
    margin-top: 0;
    padding: 50px;
  }
}

@media (max-width: 576px) {

  .what-we-do .content-box{
    padding: 40px 20px;
  }

}


/* why-us */

.why-us .image-wrapper{
  position: relative;
}
.why-us .image-wrapper span{
    position: absolute;
    left: -20px;
    top: 30px;
    width: 170px;
    height: 170px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 20px;
    color: #fff;
    border-radius: 50%;
    background-color: #f36525;
    line-height: 1.2;
}

.why-us .content-wrapper{
  padding-left: 40px;
}

.why-us .content-wrapper .sigma_icon-block-content h5{
  margin-bottom: 10px;
  font-size: 18px;
}

.why-us .content-wrapper .contact-info{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 50px;
}

.why-us .content-wrapper .contact-info .icon-block{
    background-color: #313131;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 42px;
    color: #fff;
    width: 25%;
    height: 100px;
}

.why-us .content-wrapper .contact-info .detail-block{
    background-color: #f36525;
    display: flex;
    padding-left: 45px;
    justify-content: center;
    flex-direction: column;
    width: 75%;
    height: 100px;
}
.why-us .content-wrapper .contact-info .detail-block span{
  color: #fff;
  display: block;
  font-size: 15px;
}

.why-us .content-wrapper .contact-info .detail-block h5{
  color: #fff;
  margin-bottom: 0;
  font-size: 26px;
}

@media (max-width: 991px) {

  .why-us .content-wrapper{
    padding-left: 0;
    margin-top: 50px;
  }
}

@media(max-width: 400px){

  .why-us .content-wrapper .contact-info{
    flex-direction: column;
  }
  .why-us .content-wrapper .contact-info .icon-block,
  .why-us .content-wrapper .contact-info .detail-block{
    width: 100%;
  }
}


/* why-us style 2 */

.why-us.style-2{
  position: relative;
}

.why-us.style-2 .row .col-lg-3{
  padding-top: 120px;
}

.why-us.style-2 .content-block{
  margin-bottom: 30px;
}
.why-us.style-2 .content-block.right-aligned{
  text-align: right;
}

.why-us.style-2 .content-block .flex-content{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}

.why-us.style-2 .content-block .flex-content .icon-block{
  font-size: 35px;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f36525;
  border: 5px solid #fff;
  box-shadow: 0 3px 12px rgba(0,0,0,.1);
  border-radius: 50%;
  color: #fff;
  line-height: 1;
  transition: .3s;
}

.why-us.style-2 .content-block .flex-content h5{
  color: #fff;
  margin-bottom: 0;
  font-size: 20px;
}
.why-us.style-2 .image-wrapper{
  bottom: -80px;
}
.why-us.style-2 .image-wrapper img{
  width: 100%;
}

@media(max-width: 1199px) and (min-width: 991px){

  .why-us.style-2 .content-block .flex-content{
    display: block;
  }
  .why-us.style-2 .content-block.right-aligned .icon-block{
    margin-left: auto;
  }
}

@media (max-width: 991px) {

  .why-us.style-2 .image-wrapper{
    bottom: 0;
    margin: 50px 0 80px;
  }
  .why-us.style-2 .row .col-lg-3{
    padding-top: 0;
  }
  .why-us.style-2 .content-block{
    max-width: 300px;
    margin: 0 auto 30px;
  }
}
