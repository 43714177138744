/* You can add global styles to this file, and also import other style files */

button:focus,
:focus {
    outline: none;
}
.cart-open.sigma_cart-sidebar-wrapper.sigma_aside {
    right: 0;
	overflow: hidden;
}
.cart-open.sigma_aside-overlay.sigma_cart-sidebar-overlay {
    opacity: 1;
    visibility: visible;
}
.sigma_post-thumb.post-format-gallery .slick-arrow{
	left: 0;
	right: 0;
    z-index: 10;
    opacity: 1;
    visibility: visible;
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #313131;
	color: #fff;
	position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 0;
    border: none;
    appearance: none;
	cursor: pointer;
}
.sigma_post-thumb.post-format-gallery .slick-arrow.slick-prev::before,
.sigma_post-thumb.post-format-gallery .slick-arrow.slick-next::before{
	color: #fff;
	font-size: 14px;
}
.sigma_post-thumb.post-format-gallery .slick-arrow.slick-prev{
	font-size: 0;
}
.sigma_post-thumb.post-format-gallery .slick-arrow.slick-next{
	font-size: 0;
	left: auto;
}
.post-format-quote + .sigma_post-body,
.post-format-image + .sigma_post-body{
	display: none;
}
.accordion .card-header{
	padding: 0;
}
.accordion .card .card-header .btn{
    width: 100%;
    text-align: left;
    text-decoration: none;
    border-radius: 0;
	display: flex;
}
.sigma_product-controls a.active{
    margin-top: 0;
    background-color: #f36525;
    color: #fff;
    box-shadow: 0px 10px 50px 0px rgb(53 82 99 / 9%);
}
.sigma_btn-custom.light.active{
	color: #fff;
	background-color: #313130;
}
td .qty{
	width: fit-content;
}
.sigma_notice-content.show{
	display: block;
}
/* Pagination */
pagination-template .ngx-pagination {
	margin-top: 50px;
	justify-content: center;
	margin-bottom: 0;
	display: flex;
	padding-left: 0;
	list-style: none;
	border-radius: .25rem;
}
pagination-template .ngx-pagination li {
	margin: 0;
	position: relative;
}
pagination-template .ngx-pagination li.pagination-next, pagination-template .ngx-pagination li.pagination-previous {
	padding: 0;
	display: block;
}
pagination-template .ngx-pagination li.pagination-next span, pagination-template .ngx-pagination li.pagination-previous span, pagination-template .ngx-pagination li a {
	border-radius: 50%;
	color: #313131;
	border: 1px solid #efefef;
	background-color: #ffffff;
	font-size: 14px;
	font-weight: 600;
	padding: 15px 21px!important;
	margin: 0;
	position: relative;
	margin-left: 15px;
	display: block;
	line-height: 1.25;
}
pagination-template .ngx-pagination li.current {
	border-radius: 50%;
	color: #fff;
	border: 1px solid #313131;
	background-color: #313131;
	font-size: 14px;
	font-weight: 600;
	padding: 15px 21px!important;
	margin: 0;
	position: relative;
	margin-left: 15px;
	display: block;
	line-height: 1.25;
}
pagination-template .ngx-pagination li.pagination-next::after, pagination-template .ngx-pagination li.pagination-next a:after, pagination-template .ngx-pagination li.pagination-previous a::before, pagination-template .ngx-pagination li.pagination-previous::before {
	display: none!important;
}
/* Range slider */
.ngx-slider .ngx-slider-bubble{
    padding: 1px 5px!important;
    color: #ffffff!important;
    font-size: 10px!important;
    background-color: #f36525;
    border-radius: 4px;
}
.ngx-slider .ngx-slider-bubble::after{
    position: absolute;
    display: block;
    content: "";
    bottom: -6px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-top-color: #f36525;
}
.ngx-slider .ngx-slider-bar{
    background: #e1e4e9!important;
    height: 12px!important;
    border-radius: 4px!important;
}
.ngx-slider .ngx-slider-selection{
    background: #f36525!important;
    border-radius: 0px!important;
}
.ngx-slider .ngx-slider-pointer:after {
    display: none!important;
}
.ngx-slider .ngx-slider-pointer{
    background-color: #f36525!important;
	width: 2px!important;
    height: 18px!important;
    top: -3px!important;
}
.widget-recent-posts .sigma_recent-post>a{
	height: 75px;
}
.widget-recent-posts .sigma_recent-post>a>img{
	width: 100%;
    height: 100%;
    object-fit: cover;
}